import { NewsItem } from '../modules/home/gov-news/tab-news-content/all-content/all-content.container';

const CategoryNews: { [key: string]: string } = {
  administracao: 'Administração',
  cultura: 'Cultura',
  habitacao: 'Habitação',
  desenvolvimento: 'Desenvolvimento',
};

export const reorganizeNews = <T extends Partial<NewsItem>>(
  news: Record<string, T[]>,
  itemsPerGroup = 2
): Record<number, T[]> => {
  const newNews: Record<number, T[]> = {};
  let counter = 1;
  let tempArray: T[] = [];

  for (const key in news) {
    for (const item of news[key]) {
      tempArray.push(item);
      if (tempArray.length === itemsPerGroup) {
        newNews[counter] = [...tempArray];
        tempArray = [];
        counter++;
      }
    }
  }

  if (tempArray.length > 0) {
    newNews[counter] = [...tempArray];
  }

  return newNews;
};

export const generateBadgeCategory = (categories: string[]) =>
  categories
    .filter((category) => CategoryNews[category])
    .map((category) => CategoryNews[category])
    .join(' | ');
