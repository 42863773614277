import axios from 'axios';

const apiUrl = process.env.BACKEND_ENDPOINT;
export interface IDashboard {
  title: string;
  value: number;
}

export async function getDashboard(): Promise<IDashboard[]> {
  const response = await axios.get(`${apiUrl}/v1/catalog/dashboard`);

  return response.data;
}
