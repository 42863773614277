import { styled } from "@mui/material";

export const HeaderDetailStyled = styled("div")(() => ({
  padding: "16px 0px",
  backgroundColor: "var(--color-background-secondary)",
  background: "linear-gradient(to bottom, var(--color-gradient-primary) 0%, var(--color-background-secondary) 50%, var(--color-gradient-primary) 100%)",
  
  "& span": {
    width: "auto",
    display: "flex",
    alignItems: "center",
  },

  "& button": {
    marginTop: "8px",
    color: "var(--color-grey-80)",

    "&:hover": {      
      color: "var(--color-primary)",
    }
  }
}));