import React, { FC } from "react";
import { HeaderDetailStyled } from "./style";
import { Container } from "../container/container";
import {
  Breadcrumbs as Bread,
  BreadcrumbsProps,
  Button,
} from "@plataformaxvia/design-system";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export interface HeaderDetailProps {
  breadcrumbs: BreadcrumbsProps;
}

export const HeaderDetail: FC<HeaderDetailProps> = ({ breadcrumbs }) => {
  return (
    <HeaderDetailStyled>
      <Container>
        <Bread {...breadcrumbs} />
        <Button
          iconStart={<KeyboardBackspaceIcon />}
          label="Voltar"
          onClick={() => window.history.back()}
          size="none"
          variant="text"
        />
      </Container>
    </HeaderDetailStyled>
  );
};
