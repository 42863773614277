import { FC, useEffect } from 'react';
import { FooterContainer } from '../modules/footer/footer.container';
import { NewsListContainer } from '../modules/news-list/news-list.container';

export const NewsList: FC = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <NewsListContainer />
      <FooterContainer />
    </>
  );
};
