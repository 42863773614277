import { styled } from "@mui/material";

export const BaseHeaderTitle = styled("div")(() => ({
  position: "relative",
  padding: "46px 0px",
  backgroundColor: "var(--color-background-dark)",
  overflow: "hidden",

  "& h1": {
    color: "var(--xvia-color-title-contrast)",
  },
}));

export const GradientOverlay = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%)",
  opacity: 0.10
}));