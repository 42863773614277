import { FC } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EmailIcon from '@mui/icons-material/Email';
import {
  IMidia,
  SocialMidia,
} from '../../components/social-midia/social-midia';
import {
  ContainerExternalLinks,
  ContainerInternalLinks,
  ExternalLinksBlock,
  FooterContainer,
  FooterContainerGovInfo,
  FooterExtenalTitle,
  GovInfoFooterBlock,
  GovInfoFooterContainer,
  GovInfoFooterItem,
  LinkItemBlock,
  PartnerFooterLogos,
  SocialLinksBLock,
} from './style';
import { Container } from '../../components/container/container';

export interface ILinkItem {
  text: string;
  link: string;
}

export interface FooterProps {
  internalLinks: ILinkItem[];
  externalLinks: ILinkItem[];
  social: IMidia[];
}

export const Footer: FC<FooterProps> = ({
  internalLinks,
  externalLinks,
  social,
}) => {
  return (
    <>
      <FooterContainer>
        <Container>
          <ContainerInternalLinks>
            {internalLinks?.map(({ text, link }, index) => (
              <LinkItemBlock href={link} target="_blank" key={index}>
                <hr />
                <span>{text}</span>
              </LinkItemBlock>
            ))}
          </ContainerInternalLinks>

          <ContainerExternalLinks>
            <FooterExtenalTitle>Links externos</FooterExtenalTitle>
            <ExternalLinksBlock>
              {externalLinks?.map(({ text, link }, index) => (
                <LinkItemBlock href={link} target="_blank" key={index}>
                  <hr />
                  <span>{text}</span>
                </LinkItemBlock>
              ))}
            </ExternalLinksBlock>
          </ContainerExternalLinks>
        </Container>
      </FooterContainer>

      <FooterContainerGovInfo>
        <Container>
          <SocialLinksBLock>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/gov_pe_logo.png`}
                alt="Logo do Governo de Pernambuco"
                width={220}
              />
            </div>

            <div>
              <SocialMidia midiasList={social} />
            </div>
          </SocialLinksBLock>
          <hr />
          <GovInfoFooterContainer>
            <GovInfoFooterBlock>
              <GovInfoFooterItem>
                <LocalPhoneIcon />
                <span>+55 81 3181.2100</span>
              </GovInfoFooterItem>

              <GovInfoFooterItem
                href=" mailto:servicocidadao@sad.pe.gov.br"
                target="_blank"
              >
                <EmailIcon />
                <span>servicocidadao@sad.pe.gov.br</span>
              </GovInfoFooterItem>

              <GovInfoFooterItem
                href="https://goo.gl/maps/6iX11z31915Z254C9"
                target="_blank"
              >
                <FmdGoodIcon />
                <span>
                  Praça da República, Bairro de Santo Antônio, Recife – PE, CEP
                  50.010-928
                </span>
              </GovInfoFooterItem>
            </GovInfoFooterBlock>

            <PartnerFooterLogos>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo_ati.png`}
                alt="Logo agência estadual de tecnologia da informação"
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/logo_liga_digital.png`}
                alt="Logo liga digital, governo digital de Pernambuco"
              />
            </PartnerFooterLogos>
          </GovInfoFooterContainer>
        </Container>
      </FooterContainerGovInfo>
    </>
  );
};
