import React, { FC } from "react";
import { Spin } from "antd";
import { Container } from "../../components/container/container";
import Carousel from "../../components/Carousel";
import {
  CardServicesHighlight,
  CardServicesHighlightProps,
} from "@plataformaxvia/design-system";
import { RelevantServiceWrap } from "./style";
import { useHistory } from "react-router-dom";

export interface RelevantServicesProps {
  services?: CardServicesHighlightProps[];
}

export const RelevantServices: FC<RelevantServicesProps> = ({ services }) => {  
  let history = useHistory();
  return (
    <RelevantServiceWrap>
      <Container className="RelevantServiceWrap">
        {!!services && (
          <Carousel
            slidesToShow={4}
            titleLabel="Serviços em Destaque"
            buttonLabel="Mais serviços"
            buttonAction={() => history.push('carta-de-servicos')}
          >
            {services.map((service, index) => (
              <CardServicesHighlight
                key={index}
                info={service?.info ?? ""}
                title={service.title}
                imageUrl={service?.imageUrl}
                onClick={service.onClick}
              />
            ))}
          </Carousel>
        )}
        {!services && (
          <div className={"xvia-relevant-services__loading"} aria-live="polite">
            <Spin />
          </div>
        )}
      </Container>
    </RelevantServiceWrap>
  );
};
