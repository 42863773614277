import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NewsList } from './news-list';
import {
  getCountNews,
  getCountNewsByCategory,
  getNews,
  getNewsByCategory,
  NewsCardProps,
} from '../../apis/news-rss';
import { useHistory } from 'react-router-dom';

const TOTAL_ITEMS_PER_PAGE = 18;
const TABS = [
  { id: 'todas', name: 'Todas' },
  { id: 'saude', name: 'Saúde' },
  { id: 'educacao', name: 'Educação' },
  { id: 'seguranca', name: 'Segurança' },
  { id: 'meio-ambiente', name: 'Meio Ambiente' },
  { id: 'cultura', name: 'Cultura' },
  { id: 'infraestrutura', name: 'Infraestrutura' },
  { id: 'transito', name: 'Trânsito' },
];

export const NewsListContainer: FC = () => {
  let history = useHistory();
  const [tabActivated, setTabActivated] = useState('#todas');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [news, setNews] = useState<NewsCardProps[]>([]);

  const category = useMemo(
    () =>
      tabActivated === '#todas' ? undefined : tabActivated.replace('#', ''),
    [tabActivated]
  );
  const pageOffset = useMemo(
    () => (currentPage - 1) * TOTAL_ITEMS_PER_PAGE,
    [currentPage]
  );

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/noticias/${slug}`);
    },
    [history]
  );

  const handleToggle = (hash: string) => {
    setTabActivated(hash);
    setCurrentPage(1);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchNews = useCallback(async () => {
    const response = await getNews({
      limit: TOTAL_ITEMS_PER_PAGE,
      offset: pageOffset,
    });

    setNews(response);
  }, [pageOffset]);

  const fetchTotalNewsPages = useCallback(async () => {
    const count = await getCountNews();
    setTotalPages(Math.ceil(count / TOTAL_ITEMS_PER_PAGE));
  }, []);

  const fetchNewsByCategory = useCallback(
    async (category: string) => {
      const response = await getNewsByCategory({
        category,
        limit: TOTAL_ITEMS_PER_PAGE,
        offset: pageOffset,
      });

      setNews(response);
    },
    [pageOffset]
  );

  const fetchTotalNewsPagesByCategory = useCallback(
    async (category: string) => {
      const count = await getCountNewsByCategory({ category });
      setTotalPages(Math.ceil(count / TOTAL_ITEMS_PER_PAGE));
    },
    []
  );

  useEffect(() => {
    if (category) {
      fetchNewsByCategory(category);
      fetchTotalNewsPagesByCategory(category);
    } else {
      fetchNews();
      fetchTotalNewsPages();
    }
  }, [
    category,
    fetchNews,
    fetchNewsByCategory,
    fetchTotalNewsPages,
    fetchTotalNewsPagesByCategory,
  ]);

  const props = {
    handleToggle,
    tabActivated,
    currentPage,
    onPageChange,
    totalPages,
    tabs: TABS,
    newsCards: news,
    onClick: (slug: string) => onClickCard(slug),
  };

  return createElement(NewsList, props);
};
