import { styled } from '@mui/material';

interface CustomPagingProps {
  currentSlide: boolean;
}

interface BaseCarouselProps {
  hasArrows: boolean;
}

export const Wrap = styled('div')(() => ({
  width: '100%',
}));

export const WrapTitle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

export const BaseCarousel = styled('div')<BaseCarouselProps>(
  ({ hasArrows }) => ({
    position: 'relative',
    paddingLeft: hasArrows ? '48px' : '0px',
    paddingRight: hasArrows ? '48px' : '0px',
  })
);

export const SliderStyled = styled('div')(() => ({
  '& .slick-list': {
    padding: '20px 0px 40px 0px',
    overflow: 'hidden',

    '& div': {
      margin: 'auto',
    },
  },

  '& .slick-dots li div': {
    backgroundColor: "var(--color-background-dot)",
    
    '&:hover': {
      border: "2px solid var(--color-background-dot-active)",
    },
  },

  '& .slick-dots li.slick-active div': {
    backgroundColor: "var(--color-background-dot-active)",

    '&:hover': {
      border: "2px solid var(--color-background-dot-active)",
    },
  },
}));

export const CustomPagingStyled = styled('div')<CustomPagingProps>(
  ({ currentSlide }) => ({
    width: '10px',
    height: '10px',
    color: 'blue',
    backgroundColor: currentSlide
      ? 'var(--color-primary)'
      : 'var(--color-grey-10)',
    border: `1px solid ${currentSlide ? 'var(--color-primary)' : '#E5E7EB'}`,
    borderRadius: '25px',
    marginTop: '-20px',

    '&:hover': {
      border: '2px solid var(--color-primary)',
    },
  })
);

export const IconButtonleft = styled('div')(() => ({
  position: 'absolute',
  top: 'calc(50% - 15px)',
  left: 0,

  '& button': {
    padding: '12px !important',
    transition: 'background-color .5s ease, transform .5s ease',
    borderRadius: '4px',

    backgroundColor: 'var(--color-button-background-fill-arrow)',
    border: 'var(--color-button-border-arrow)',
    color: 'var(--color-icon-arrow)',
  },

  '& button:hover': {
    backgroundColor: 'var(--color-button-background-arrow-hover)',
    border: 'var(--color-button-border-arrow-hover)',
    color: 'var(--color-icon-arrow-hover)',
  },

  '& svg': {
    fontSize: '20px',
  },
}));

export const IconButtonRight = styled('div')(() => ({
  position: 'absolute',
  top: 'calc(50% - 15px)',
  right: 0,

  '& button': {
    padding: '12px !important',
    transition: 'background-color .5s ease, transform .5s ease',
    borderRadius: '4px',

    backgroundColor: 'var(--color-button-background-fill-arrow)',
    border: 'var(--color-button-border-arrow)',
    color: 'var(--color-icon-arrow)',
  },

  '& button:hover': {
    backgroundColor: 'var(--color-button-background-arrow-hover)',
    border: 'var(--color-button-border-arrow-hover)',
    color: 'var(--color-icon-arrow-hover)',
  },

  '& svg': {
    fontSize: '20px',
  },
}));
