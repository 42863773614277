import React from 'react';
import { Container } from '../../components/container/container';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ListWrap } from './style';
import { ServicesContainer } from '../services/services.container';

export const List = () => {
  return (
    <>
      <HeaderTitle title="Serviços" />
      <ListWrap>
        <Container>
          <ServicesContainer title="Todos" totalServices />
        </Container>
      </ListWrap>
    </>
  );
};
