import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Indicator, Indicators, IndicatorsProps } from './indicators';
import { getServerData } from '../../util/get-server-data';
import { useWebgate } from '@xvia/webgate-connect';
import { getDashboard, IDashboard } from '../../apis/dashboard';

export const IndicatorsContainer: FC = () => {
  const { serverData } = useWebgate();
  const [dashboard, setDashboard] = useState<IDashboard[]>(
    serverData?.dashboard || getServerData('dashboard', null)
  );

  const getDashboardObj = useCallback(async () => {
    const response = await getDashboard();
    setDashboard(response ?? []);
  }, []);

  useEffect(() => {
    getDashboardObj();
  }, [getDashboardObj]);

  const indicators = useMemo(() => {
    const info: { [key: string]: Indicator } = {
      catalog_count: {
        icon: 'fa-file-signature',
        detail: 'Serviços no Portal',
        value: 0,
      },
      catalog_online_count: {
        icon: 'fa-globe',
        detail: 'Serviços Online',
        value: 0,
      },
    };

    dashboard?.forEach(({ title, value }) => {
      if (title === 'catalog_count') {
        info.catalog_count.value += value;
      }
      if (
        title === 'catalog_online_count' ||
        title === 'catalog_digital_count'
      ) {
        info.catalog_online_count.value += value;
      }
    });

    return Object.keys(info).map((infoKey) => ({
      icon: info[infoKey].icon,
      value: info[infoKey].value,
      detail: info[infoKey].detail,
    }));
  }, [dashboard]);

  const props: IndicatorsProps = { indicators };

  return <Indicators {...props} />;
};
