import { FC } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { SocialLinkItem, SocialMidiaContainer } from './style';

export interface IMidia {
  Icon: SvgIconComponent;
  link: string;
}

export interface SocialMidiaProps {
  midiasList: IMidia[];
}

export const SocialMidia: FC<SocialMidiaProps> = ({ midiasList }) => {
  return (
    <SocialMidiaContainer>
      {midiasList.map(({ link, Icon }) => {
        return (
          <SocialLinkItem
            href={link}
            target={'_blank'}
            rel="noreferrer"
            key={link}
          >
            <Icon className="xvia-social-midia__icon" />
          </SocialLinkItem>
        );
      })}
    </SocialMidiaContainer>
  );
};
