import React, {
  DetailedHTMLProps,
  FC,
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import { SearchButtonWrapper, SearchInputContainer, SearchInputStyled, SearchInputWrap } from "./style";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export interface SearchInputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  onClickSearch: any;
}

export const SearchInput: FC<SearchInputProps> = ({
  className,
  onClickSearch,
  placeholder = "Buscar...",
  ...inputProps
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(420));
  const [search, setSearch] = useState("");
  const wrapperClassName = useMemo(
    () =>
      classNames({
        "xvia-search-input": true,
        [className as string]: !!className,
      }),
    [className]
  );

  const keyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        onClickSearch(search);
      }
    },
    [onClickSearch, search]
  );

  return (
    <div className={wrapperClassName}>
      <SearchInputWrap>
        <SearchInputContainer>
          <SearchIcon />
          <SearchInputStyled
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={keyDownHandler}
            value={search}
            className="xvia-catalog-search-input__input"
            type="text"
            name="keyword"
            aria-label="Campo de busca"
            placeholder={placeholder}
            {...inputProps}
          />
        </SearchInputContainer>
        <SearchButtonWrapper>
          <button onClick={() => onClickSearch(search)}>{isSmallScreen ? <SearchIcon fontSize="small" /> : "Buscar"}</button>
        </SearchButtonWrapper>
      </SearchInputWrap>
    </div>
  );
};