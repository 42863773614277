import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NewsDetail, NewsDetailProps } from './news-detail';
import {
  getNewsBySlug,
  getRelatedNewsBySlug,
  NewsCardProps,
  PostProps,
} from '../../apis/news-rss';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { generateBadgeCategory } from '../../util/news';
import { useHistory } from 'react-router-dom';

export const NewsDetailContainer: FC = () => {
  let history = useHistory();
  const [currentNews, setCurrentNews] = useState<PostProps | null>(null);
  const [relatedNews, setRelatedNews] = useState<NewsCardProps[]>([]);
  const { slug } = useParams<{ slug: string }>();

  const category = useMemo(
    () => currentNews?.categories?.[0] ?? 'destaque',
    [currentNews?.categories]
  );
  const breadcrumb = useMemo(
    () => [
      { name: 'Início', path: ROUTES.HOME_PAGE },
      { name: 'Notícias', path: `/app/catalog${ROUTES.NEWS_PAGE}` },
      { name: currentNews?.title ?? 'Carregando...' },
    ],
    [currentNews?.title]
  );

  const fetchNewsBySlug = useCallback(async (slug: string) => {
    const response = await getNewsBySlug(slug);

    setCurrentNews(response);
  }, []);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/noticias/${slug}`);
    },
    [history]
  );

  const fetchRelatedNewsBySlug = useCallback(
    async (slug: string, category: string) => {
      const response = await getRelatedNewsBySlug({
        slug,
        category,
        limit: 4,
        offset: 0,
      });

      setRelatedNews(response);
    },
    []
  );

  useEffect(() => {
    fetchNewsBySlug(slug);
  }, [fetchNewsBySlug, slug]);

  useEffect(() => {
    if (!currentNews) return;

    fetchRelatedNewsBySlug(slug, category);
  }, [category, currentNews, fetchRelatedNewsBySlug, slug]);

  if (!currentNews) {
    return <div>Carregando...</div>;
  }

  const props: NewsDetailProps = {
    breadcrumb,
    category: generateBadgeCategory(currentNews.categories),
    content: currentNews.content,
    thumbnail: currentNews.thumbnail!,
    title: currentNews.title,
    author: currentNews.author,
    relatedNews,
    onClick: (slug: string) => onClickCard(slug),
  };

  return createElement(NewsDetail, props);
};
