import { Box, Divider, Grid, styled } from '@mui/material';

export const DetailWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const RelatedWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-secondary)',
}));

export const StyledGrid = styled(Grid)(() => ({
  width: '100%',
}));

export const StyledBoxGrid = styled(Box)(() => ({
  marginTop: '16px',

  '& img': {
    maxWidth: '100%',
    marginTop: '24px',
    marginBottom: '24px',
  },
}));

export const DividerStyled = styled(Divider)(() => ({
  margin: '24px 0px',
}));

export const AuthorStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  fontSize: 'var(--font-body-1)',
  color: 'var(--color-grey-80)',

  '& svg': {
    color: 'var(--color-secondary)',
  },
}));
