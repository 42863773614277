export const capitalizeTitle = (text: string) => {
    if (!text) return '';

    const exceptions = [
      'de',
      'da',
      'do',
      'dos',
      'das',
      'e',
      'a',
      'o',
      'para',
      'com',
      'em',
      'sem',
      'por',
      'sob',
      'sobre',
    ];

    return text
      .toLowerCase()
      .split(' ')
      .map((word, index) =>
        exceptions.includes(word) && index !== 0
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(' ');
  };