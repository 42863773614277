import {
  Divider,
  styled,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@mui/material';

interface BaseAccordionProps {
  isExpanded: boolean;
}

export const DetailBase = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const CatalogTitleBase = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  alignItems: 'center',
  [theme.breakpoints.down(720)]: {
    flexDirection: 'column',
    gap: '32px',
  },
}));

export const CatalogTitle = styled('div')(() => ({
  flex: 1,
}));

export const CatalogButton = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center !important',
  textAlign: 'center',

  '& button': {
    paddingLeft: '60px',
    paddingRight: '60px',
  },

  [theme.breakpoints.down(720)]: {
    alignItems: 'start !important',
    width: '100%',
  },
}));

export const DividerStyled = styled(Divider)(() => ({
  margin: '50px 0px',
}));

export const CatalogDetailStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(994)]: {
    flexDirection: 'column',
  },
}));

export const DetailStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  flex: 1,
}));

export const ServiceLocationBase = styled('div')(() => ({
  maxWidth: '384px',
}));

export const BaseAccordion = styled('div')<BaseAccordionProps>(
  ({ isExpanded }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '&:hover .marker': {
      backgroundColor: isExpanded
        ? 'var(--color-grey-40)'
        : 'var(--color-secondary)',
    },
  })
);

export const AccordionMarker = styled('div')(() => ({
  minWidth: '5px',
  alignSelf: 'stretch',
  borderRadius: '5px',
  backgroundColor: 'var(--color-grey-40)',
  transition: 'all 0.5s ease-in-out',
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  fontSize: 'var(--font-subtitle-1)',
  color: 'var(--color-grey-90)',
  fontWeight: 500,
  minHeight: 'unset',
  padding: '0px',
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px !important',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'unset',
  },
  '& svg': {
    color: 'var(--color-primary)',
  },
}));

export const AccordionStyled = styled(Accordion)(() => ({
  width: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: '0px',
  margin: '0px !important',
  padding: '0px',

  '&:before': {
    display: 'none',
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails)({
  paddingBottom: '0px',
  paddingLeft: '0px',
  fontSize: 'var(--font-body-1)',
  lineHeight: 'var(--line-height-body-1)',
  color: 'var(--color-body-1)',  

  '& a:hover': {
    color: "var(--color-link-contrast-hover)",
    backgroundColor: 'var(--color-background-link-contrast-hover)',
  },
});

export const AccordionDetailsInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
});
