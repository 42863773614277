import Slider from "react-slick";
import {
  BaseCarousel,
  CustomPagingStyled,
  IconButtonRight,
  IconButtonleft,
  SliderStyled,
  Wrap,
  WrapTitle,
} from "./style";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Title } from "@plataformaxvia/design-system";

export interface CarouselPros {
  children: any;
  slidesToShow?: number;
  autoplay?: boolean;
  dots?: boolean;
  speed?: number;
  slidesToScroll?: number;
  arrows?: boolean;
  titleLabel?: string;
  buttonLabel?: string;
  buttonAction?: React.MouseEventHandler<Element>;
}

export default function Carousel({
  children,
  slidesToShow = 4,
  autoplay = false,
  dots = true,
  speed = 500,
  slidesToScroll = 1,
  arrows = true,
  titleLabel,
  buttonLabel,
  buttonAction,
}: CarouselPros) {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots,
    infinite: true,
    slidesToShow,
    slidesToScroll,
    arrows: false,
    autoplay,
    autoplaySpeed: speed,
    pauseOnHover: true,
    beforeChange: (_oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
    customPaging: (i: number) => (
      <CustomPagingStyled currentSlide={currentSlide === i} />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.max(slidesToShow - 1, 1),
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: Math.max(slidesToShow - 2, 1),
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrap>
      {titleLabel && (
        <WrapTitle>
          <div>
            <Title title={titleLabel}/>
          </div>
          <Button
            label={buttonLabel}
            size="large"
            iconEnd={<ArrowForwardIcon />}
            variant="primary"
            style={{width: "auto"}}
            onClick={buttonAction}
          />
        </WrapTitle>
      )}
      <BaseCarousel hasArrows={arrows}>
        <SliderStyled>
          <Slider ref={(slider) => setSliderRef(slider)} {...settings}>
            {children}
          </Slider>
        </SliderStyled>
        {arrows && (
          <>
            <IconButtonleft>
              <Button
                icon={<ArrowBackIcon />}
                label="Button"
                size="none"
                variant="icon"
                onClick={sliderRef?.slickPrev}
              />
            </IconButtonleft>
            <IconButtonRight>
              <Button
                icon={<ArrowForwardIcon />}
                label="Button"
                size="none"
                variant="icon"
                onClick={sliderRef?.slickNext}
              />
            </IconButtonRight>
          </>
        )}
      </BaseCarousel>
    </Wrap>
  );
}
