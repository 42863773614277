import React, { FC, useCallback, useEffect, useState } from 'react';

import { Container } from '../../components/container/container';
import {
  EmptyFilter,
  ServicesBase,
  ServicesTitleBase,
  SideFilterBase,
  StyledGridServiceCard,
  TopServicesBase,
  TopServicesWrap,
  TotalServices,
} from './style';
import {
  Button,
  CardServices,
  EnumPagination,
  SideFilter,
  Title,
} from '@plataformaxvia/design-system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import WarningIcon from '@mui/icons-material/Warning';
import { WarningModal } from '../../components/warning-modal/WarningModal';
import { ContainerModalAction } from '../../components/warning-modal/style';
import { ICategory } from '../../apis/categorys';
import { IDepartment } from '../../apis/department';
import { IFilter } from '../../hooks/use-services-filters';
interface SwitchDataProps {
  id: string;
  label: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

interface DropdownItem {
  id: string;
  label: string;
  checked: boolean;
  setChecked: () => void;
}

interface DropdownData {
  id: string;
  title: string;
  active: boolean;
  onToggle: () => void;
  dropdownItems: DropdownItem[];
}

interface CardServicesPros {
  title: string;
  category: string;
  onInfoClick: () => void;
  onRequestClick: () => void;
  isDigital: boolean;
  isOnline: boolean;
  totalServices: number;
}

export interface RelevantServicesProps {
  services?: CardServicesPros[];
  switchData: SwitchDataProps[];
  dropdownData: DropdownData[];
  setCurrentPage: (page: number) => void;
  currentPage: number;
  totalPages: number;
  setSearch: (value: string) => void;
  onSearchClick: () => void;
  title: string;
  element?: React.ReactNode;
  totalServices?: boolean;
  isRedirectWarningModalOpen?: boolean;
  toggleRedirectWarningModal: () => void;
  handleRedirectWarningModalConfirm: () => void;
  selectedCategory: string;
  categories: ICategory[];
  selectedDepartment: string;
  departments: IDepartment[];
  filters: IFilter;
}

export const Services: FC<RelevantServicesProps> = ({
  services,
  setCurrentPage,
  currentPage,
  totalPages,
  setSearch,
  onSearchClick,
  switchData,
  dropdownData,
  title,
  element,
  totalServices,
  isRedirectWarningModalOpen = false,
  toggleRedirectWarningModal,
  handleRedirectWarningModalConfirm,
  selectedCategory,
  categories,
  selectedDepartment,
  departments,
  filters,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const isSmallScreenFilter = useMediaQuery(theme.breakpoints.down(780));
  const [activeFilter, setActiveFilter] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleToggleFilter = useCallback(() => {
    setActiveFilter(!activeFilter);
  }, [activeFilter]);

  const categoryDisplay = categories.find(
    (category) => category.slug === selectedCategory
  )?.name;
  const departmentDisplay = departments.find(
    (department) => department.slug === selectedDepartment
  )?.name;

  const getCardServiceTitle = useCallback(() => {
    if (categoryDisplay && departmentDisplay) {
      return `${categoryDisplay} - ${departmentDisplay}`;
    }

    if (categoryDisplay) {
      return categoryDisplay;
    }

    if (departmentDisplay) {
      return departmentDisplay;
    }

    return title;
  }, [title, categoryDisplay, departmentDisplay]);

  const onChangeInputValue = (newValue: string) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    onChangeInputValue(filters.searchQuery);
    setSearch(filters.searchQuery);
  }, [filters.searchQuery, setSearch]);

  useEffect(() => {
    setSearch(inputValue);
  }, [inputValue, setSearch]);

  return (
    <TopServicesWrap>
      <WarningModal
        headerTitle="Atenção!"
        icon={<WarningIcon />}
        headerBgColor="var(--color-background-card-warning-header)"
        onCloseModal={toggleRedirectWarningModal}
        modalOpen={isRedirectWarningModalOpen}
      >
        <p>
          Você está sendo redirecionado para um link externo. Tem certeza que
          deseja continuar?
        </p>

        <ContainerModalAction>
          <Button
            onClick={handleRedirectWarningModalConfirm}
            label="Confirmar redirecionamento"
          />
        </ContainerModalAction>
      </WarningModal>

      <Container>
        <TopServicesBase>
          <SideFilterBase className={'filter-content xvia-catalog-search-input__input-list'}>
            <SideFilter
              title="Filtrar"
              searchPlaceholder="ex: emprego"
              onSearchChange={(event: any) =>
                onChangeInputValue(event.target.value)
              }
              onSearchClick={onSearchClick}
              switchData={switchData}
              dropdownData={dropdownData}
              active={activeFilter}
              handleToggle={handleToggleFilter}
              insideAccordion={isSmallScreenFilter ? true : false}
              searchValue={inputValue}
            />
          </SideFilterBase>
          <ServicesBase>
            <ServicesTitleBase>
              <div>
                <Title title={getCardServiceTitle()} />
              </div>
              {totalServices ? (
                <TotalServices>
                  {services && services.length > 0
                    ? services[0]?.totalServices
                    : 0}{' '}
                  serviço(s)
                </TotalServices>
              ) : (
                <>{element}</>
              )}
            </ServicesTitleBase>
            <StyledGridServiceCard container spacing={3}>
              {services &&
                services.length > 0 &&
                services?.map((service, index) => (
                  <Grid item xs={12} sm={isSmallScreen ? 12 : 6} key={index}>
                    <CardServices {...service} />
                  </Grid>
                ))}
              {services && services.length === 0 && (
                <EmptyFilter>
                  <FilterAltOffIcon />
                  Nenhum resultado encontrado para os filtros selecionados.
                  Tente ajustar os critérios de busca.
                </EmptyFilter>
              )}
            </StyledGridServiceCard>
            {totalPages > 1 && (
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <EnumPagination
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                />
              </Grid>
            )}
          </ServicesBase>
        </TopServicesBase>
      </Container>
    </TopServicesWrap>
  );
};
