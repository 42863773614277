import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const FooterContainer = styled(MuiBox)(({ theme }) => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-dark)',

  [theme.breakpoints.down('md')]: {
    padding: '56px',
  },
}));

export const LinkItemBlock = styled('a')`
  color: var(--color-link-footer);
  
  &:hover {
    background-color: var(--color-background-link-contrast-hover);
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  hr {
    margin: 0 5px 0 0;
    height: 17px;
    width: 4px;
    background-color: var(--color-link-footer-hr);
    border: none;
    border-radius: 20px;
  }
`;

export const FooterExtenalTitle = styled('h2')`
  color: var(--color-link-footer);
  font-weight: 700;
  font-size: var(--font-title-h2);
  line-height: 28px;
  opacity: 0.75;
  margin: 16px 0;
`;

export const ContainerInternalLinks = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '8px',

  '& span': {
    fontSize: 'var(--font-body-1)',
    fontWeight: 500,
    lineHeight: '22px',
  },

  [theme.breakpoints.down('lg')]: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ContainerExternalLinks = styled(MuiBox)`
  margin: 32px 0 16px 0;
`;

export const ExternalLinksBlock = styled(MuiBox)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  gap: '8px',

  '& span': {
    fontSize: 'var(--font-body-1)',
    fontWeight: 500,
    lineHeight: '22px',
  },

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const FooterContainerGovInfo = styled(MuiBox)(({ theme }) => ({
  backgroundColor: 'var(--color-background-subfooter)',

  '& hr': {
    margin: 0,
    backgroundColor: 'var(--color-link-footer-hr)',
    opacity: 0.3,
    borderRadius: '20px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '0 56px',
  },
}));

export const GovInfoFooterContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px 0px 50px 0',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
  },
}));

export const GovInfoFooterBlock = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',

  '& > a:nth-of-type(2)': {
    width: '70%',
  },

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },

  [theme.breakpoints.down('lg')]: {
    '& > a:nth-of-type(2)': {
      width: 'auto',
    },
  },
}));

export const GovInfoFooterItem = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'var(--color-link-footer)',
  margin: '8px 16px 0 0',

  '& svg': {
    marginRight: '8px',
  },

  '& span': {
    fontSize: 'var(--font-body-1)',
  },
}));

export const PartnerFooterLogos = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
}));

export const SocialLinksBLock = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  padding: '32px 0',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
}));
