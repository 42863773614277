import { Box, styled } from "@mui/material";

interface FeaturedNewsProps {
  imageUrl?: string
}

export const NewsWrap = styled("div")(() => ({
  padding: "56px 0px",
  backgroundColor: "var(--color-background-secondary)",
}));

export const NewsWrapTitle = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export const NewsContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  width: "100%",
  marginTop: "24px",
  gap: "32px",  
  [theme.breakpoints.down(995)]: {
    flexDirection: "column",
  },
}));

export const MainNews = styled("div")(() => ({
  width: "100%",
  flex: 1,
  height: "444px",
  borderRadius: "8px",
}));

export const MoreNews = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  flex: 1,
}));

export const FeaturedNews = styled(Box)<FeaturedNewsProps>(({ theme, imageUrl }) => ({
  position: "relative",
  overflow: "hidden",
  height: "444px",
  width: "100%",
  backgroundColor: "var(--color-grey-40)",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "height 0.8s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    height: "300px",
  },
  "&:hover .overlay": {
    background: "rgba(0, 0, 0, 0.5)",
  },
  "& .hideIcon": {
    color: "var(--color-grey-10)",
  }
}));

export const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  gap: "0.5rem",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "end",
  color: "var(--color-grey-10)",
  fontSize: "var(--font-body-1)",
  lineHeight: "var(--line-height-body-1)",
  transition: "background 0.8s ease-in-out",

  "& p": {
    color: "var(--color-grey-10)",
    fontSize: "var(--font-subtitle-3)",
    lineHeight: "var(--line-height-subtitle-3)",
    fontWeight: 300,
  },
});

export const TextContainer = styled(Box)({
  width: "100%",
  height: "30%",
  padding: "16px",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent)",
  color: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "end",
  gap: "0.5rem",
});
