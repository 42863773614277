import { FunctionComponent } from 'react';
import { Home } from './routes/Home';
import { ServiceList } from './routes/ServiceList';
import { Catalog } from './routes/Catalog';
import { DepartmentList } from './routes/DepartmentList';
import { NewsList } from './routes/NewsList';
import { News } from './routes/News';
import { NewsFeaturedList } from './routes/NewsFeaturedList';

interface IRoutes {
  path: string;
  component: FunctionComponent<any>;
  exact?: boolean;
}

export const ROUTES = {
  HOME_PAGE: '/',
  DEPARTMENT_LIST_PAGE: '/secretarias-e-orgaos',
  DEPARTMENT_PAGE: '/secretarias-e-orgaos/:department',
  SERVICE_LIST_PAGE: '/carta-de-servicos',
  SEARCH_SERVICE_PAGE: '/search/:slug',
  SERVICE_PAGE: '/carta-de-servicos/:service',
  NEWS_FEATURED_PAGE: '/todas-noticias', 
  NEWS_PAGE: '/noticias',
  NEWS_DETAIL_PAGE: '/noticias/:slug',
  PUBLIC_INFO_PAGE: '/informacoes-publicas',
  DEFAULT_PAGE: '*',
};

export const routes: IRoutes[] = [
  { path: ROUTES.HOME_PAGE, component: Home, exact: true },
  { path: ROUTES.DEPARTMENT_LIST_PAGE, component: DepartmentList },
  { path: ROUTES.SERVICE_LIST_PAGE, component: ServiceList, exact: true },
  { path: ROUTES.SEARCH_SERVICE_PAGE, component: ServiceList },
  { path: ROUTES.SERVICE_PAGE, component: Catalog },
  { path: ROUTES.NEWS_FEATURED_PAGE, component: NewsList, exact: true },
  { path: ROUTES.NEWS_PAGE, component: NewsFeaturedList, exact: true },
  { path: ROUTES.NEWS_DETAIL_PAGE, component: News },
  { path: ROUTES.DEFAULT_PAGE, component: Home },
];
