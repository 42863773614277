import { BaseVideoPlayer, IframeStyled } from "./style";

export const VideoPlayer = ({ videoUrl }: { videoUrl: string }) => {
  const getYouTubeEmbedUrl = (url: string) => {
    if (!url) return '';
    const videoId = new URL(url).searchParams.get('v');
    return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
  };

  return (
    <BaseVideoPlayer>
      <IframeStyled
        src={getYouTubeEmbedUrl(videoUrl)}
        title="YouTube Video Player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></IframeStyled>
    </BaseVideoPlayer>
  );
};
