import axios from 'axios';

const API_URL = 'https://nominatim.openstreetmap.org';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface OpenStreetMapResponse {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  class: string;
  type: string;
  place_rank: number;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  boundingbox: string[];
}

export async function searchAddressCoordinates(
  address: string
): Promise<OpenStreetMapResponse[]> {
  const response = await axiosInstance.get(`/search?format=json&q=${address}`);

  return response.data;
}
