import axios from 'axios';
import { Catalog } from './search-catalog';

const apiUrl = process.env.BACKEND_ENDPOINT;

type SEARCH_OPTIONS = {
  groups: string[];
  tags: string[];
  platforms: string[];
  slugs: string[];
  category: string[];
  departmentSlug: string | string[];
  departmentSection: string;
  query: string;
  top_hit: number;
  highlighted: true;
  isDigital: boolean;
  isOnline: boolean;
  from: number;
  size: number;
};

export async function getRelevant(accessToken?: string): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search`,
    {
      groups: ['CATALOG'],
      highlighted: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getTopCatalog(accessToken?: string): Promise<Catalog[]> {
  const response = await axios.post(
    `${apiUrl}/v1/search`,
    {
      top_hit: 4,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getCatalog(
  accessToken?: string,
  searchOptions?: Partial<SEARCH_OPTIONS>
): Promise<{ items: Catalog[]; totalPages: number }> {
  const from = searchOptions?.from || 0;
  const size = searchOptions?.size || 10;
  const { data } = await axios.post(
    `${apiUrl}/v1/search`,
    {
      groups: ['CATALOG'],
      from,
      size,
      ...searchOptions,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const totalItems = data[0]?.resultTotal || 0;
  const totalPages = Math.ceil(totalItems / size);

  return {
    items: data,
    totalPages,
  };
}
