import React, { FC } from 'react';
import classNames from 'classnames';
import { Title } from '../typography/title';
import { Button } from '../button/button';

export interface StepsProps {
  number?: number;
  icon?: string;
  title: string;
  inactive?: boolean;
}

export const Step: FC<StepsProps> = ({
  title,
  number,
  inactive,
  children,
  icon,
}) => {
  const containerClass = classNames({
    'xvia-step': true,
    'xvia-step--active': !inactive,
  });

  const headerClass = classNames({
    'xvia-step__header': true,
  });

  const numberClass = classNames({
    'xvia-step__number': true,
  });

  const contentClass = classNames({
    'xvia-step__content': true,
  });

  return (
    <div className={containerClass}>
      <div className={headerClass} style={{ fontSize: 20 }}>
        {icon && (
          <Button
            title={''}
            icon={`${icon}`}
            circle={true}
            onClick={() => {}}
            className={numberClass}
            aria-label={`Ícone representando o passo ${number || ''}`}
          />
        )}

        <Title level={5} id={`step-title-${number}`}>
          {title}
        </Title>
      </div>
      <div className={contentClass} aria-labelledby={`step-title-${number}`}>
        {children}
      </div>
    </div>
  );
};
