import { FC } from "react";
import {
  ContainerBgModal,
  ContainerContentModal,
  ContainerHeaderModal,
  ContainerHeaderTitle,
  ContainerModal,
  MIconButton,
} from "./style";
import CloseIcon from "@mui/icons-material/Close";

export interface IModalProps {
  icon: React.ReactNode;
  headerBgColor: string;
  headerTitle: string;
  headerSubTitle?: string;
  onCloseModal: () => void;
  children: React.ReactNode;
  modalOpen: boolean;
}

export const WarningModal: FC<IModalProps> = ({
  icon,
  headerBgColor = "transparent",
  headerTitle,
  headerSubTitle,
  onCloseModal,
  children,
  modalOpen,
}) => {
  return (
    <ContainerBgModal modalOpen={modalOpen}>
      <ContainerModal modalOpen={modalOpen}>
        <ContainerHeaderModal style={{ backgroundColor: headerBgColor }}>
          {icon && <>{icon}</>}
          <ContainerHeaderTitle>
            <h3>{headerTitle}</h3>
            {headerSubTitle && <p>{headerSubTitle}</p>}
          </ContainerHeaderTitle>

          <MIconButton aria-label="fechar" onClick={onCloseModal}>
            <CloseIcon />
          </MIconButton>
        </ContainerHeaderModal>

        <ContainerContentModal>{children}</ContainerContentModal>
      </ContainerModal>
    </ContainerBgModal>
  );
};
