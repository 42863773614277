import React from 'react';
import {
  BaseGovernor,
  BaseGovernorPhoto,
  BaseGovernorInfo,
  GovernorInfoCard,
  GovernorTitle,
  GovernorInfo,
  GovernorText,
} from './style';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

export const Governor = () => {
  return (
    <BaseGovernor>
      <BaseGovernorInfo>
        <GovernorInfoCard>
          <GovernorTitle>
            Raquel Lyra, a governadora de Pernambuco
          </GovernorTitle>
          <GovernorInfo>
            <PersonIcon fontSize="small" />
            <label>Nome completo: Raquel Teixeira Lyra Lucena</label>
          </GovernorInfo>
          <GovernorInfo>
            <CalendarTodayIcon fontSize="small" />
            <label>Nascimento: 02 de dezembro de 1978</label>
          </GovernorInfo>
          <GovernorInfo>
            <FamilyRestroomIcon fontSize="small" />
            <label>Filhos: João Lyra (12 anos) e Fernando Lyra (10 anos)</label>
          </GovernorInfo>
        </GovernorInfoCard>
        <GovernorText>
          <strong>
            Primeira mulher eleita governadora de Pernambuco, Raquel Lyra é
            advogada e política pernambucana - com forte atuação no interior do
            Estado.
          </strong>

          <p>
            Primeira mulher eleita governadora de Pernambuco, Raquel Lyra é
            advogada e política pernambucana – com forte atuação no interior do
            Estado. Ela é formada em Direito pela Universidade Federal de
            Pernambuco, é viúva e tem dois filhos: João e Fernando (Nando). Foi
            eleita em outubro de 2022 e tem Priscila Krause como sua
            vice-governadora.
          </p>

          <p>
            Primeira mulher eleita governadora de Pernambuco, Raquel Lyra é
            advogada e política pernambucana – com forte atuação no interior do
            Estado. Ela é formada em Direito pela Universidade Federal de
            Pernambuco, é viúva e tem dois filhos: João e Fernando (Nando). Foi
            eleita em outubro de 2022 e tem Priscila Krause como sua
            vice-governadora.
          </p>

          <p>
            Servidora pública por vocação, ainda muito jovem foi aprovada em
            concurso público para delegada da Polícia Federal. Foi concursada
            também do Banco do Nordeste – ainda durante a faculdade – e da
            Procuradoria do Estado de Pernambuco. De 2007 a 2010, no primeiro
            governo de Eduardo Campos, foi chefe da Procuradoria de Apoio
            Jurídico e Legislativo do governador.
          </p>

          <p>
            Na Assembleia Legislativa de Pernambuco, foi presidente da Comissão
            de Justiça, onde utilizou os conhecimentos como advogada e
            procuradora para liderar esta que é a comissão mais importante da
            Casa.
          </p>
        </GovernorText>
      </BaseGovernorInfo>
      <BaseGovernorPhoto
        src={`${process.env.PUBLIC_URL}/images/governadora.png`}
        alt="Raquel Lyra, a governadora de Pernambuco"
      />
    </BaseGovernor>
  );
};
