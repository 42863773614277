import React, { FC, useState } from 'react';
import { Container } from '../../components/container/container';
import { IDepartment } from '../../apis/department';
import {
  BaseDepartmentList,
  BaseAccordionItem,
  SideFilterBase,
  WrapDepartmentList,
  WrapSecretariatsList,
  AccordionItemStyled,
  BaseAccordionButton,
  EmptyFilter,
  BaseTabsNav,
  WrapTabsNav,
  AccordionSubtitleStyled,
  AccordionTriggerStyled,
} from './style';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  EnumPagination,
  SideFilter,
  Tabs,
  TabsContent,
  TabsNav,
  TabsTrigger,
  Title,
} from '@plataformaxvia/design-system';
import { HeaderTitle } from '../../components/header-title/header-title';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ArticleIcon from '@mui/icons-material/Article';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Governor } from './governor';
import { WarningModal } from '../../components/warning-modal/WarningModal';
import WarningIcon from '@mui/icons-material/Warning';
import { ContainerModalAction } from '../../components/warning-modal/style';
import { useRedirectWarningModal } from '../../hooks/use-redirect-warning-modal';
import { stripHtml } from '../../util/html';

interface DropdownItem {
  id: string;
  label: string;
  checked: boolean;
  setChecked: () => void;
}

interface DropdownData {
  id: string;
  title: string;
  active: boolean;
  onToggle: () => void;
  dropdownItems: DropdownItem[];
}

export interface DepartmentProps {
  departmentList?: IDepartment[];
  setSearch: (value: string) => void;
  onSearchClick: () => void;
  dropdownData: DropdownData[];
}

export const DepartmentList: FC<DepartmentProps> = ({
  departmentList,
  setSearch,
  onSearchClick,
  dropdownData,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const [active, setActive] = useState('#tab-1');
  const [actives, setActives] = React.useState<Set<string>>(new Set());
  const isSmallScreenFilter = useMediaQuery(theme.breakpoints.down(780));
  const [activeFilter, setActiveFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = departmentList && departmentList.length > 0 ? Math.ceil(departmentList.length / itemsPerPage) : 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentDepartments = departmentList?.slice(startIndex, endIndex);

  function handleToggleFilter() {
    setActiveFilter(!activeFilter);
  }

  const {
    toggleRedirectWarningModal,
    handleRedirectWarningModalConfirm,
    isRedirectWarningModalOpen,
    setCurrentServiceRedirectLink,
  } = useRedirectWarningModal();

  function handleToggles(id: string) {
    if (actives.has(id)) {
      actives.delete(id);
    } else {
      actives.add(id);
    }

    setActives(new Set(actives));
  }

  function handleToggle(hash: string) {
    setActive(hash);
  }

  function handleRedirect(link: string | undefined) {
    if (!link) return;
    setCurrentServiceRedirectLink(link);
    toggleRedirectWarningModal();
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <WarningModal
        headerTitle="Atenção!"
        icon={<WarningIcon />}
        headerBgColor="#F5A623"
        onCloseModal={toggleRedirectWarningModal}
        modalOpen={isRedirectWarningModalOpen}
      >
        <p>
          Você está sendo redirecionado para um link externo. Tem certeza que
          deseja continuar?
        </p>

        <ContainerModalAction>
          <Button
            onClick={handleRedirectWarningModalConfirm}
            label="Confirmar redirecionamento"
          />
        </ContainerModalAction>
      </WarningModal>
      <HeaderTitle title="Governo" />
      <BaseDepartmentList>
        <Container>
          <Tabs>
            <WrapTabsNav>
              <BaseTabsNav>
                <TabsNav>
                  <TabsTrigger
                    href="#tab-1"
                    onToggle={handleToggle}
                    active={active === '#tab-1'}
                  >
                    Secretarias e Órgãos
                  </TabsTrigger>
                  <TabsTrigger
                    href="#tab-2"
                    onToggle={handleToggle}
                    active={active === '#tab-2'}
                  >
                    A Governadora
                  </TabsTrigger>
                </TabsNav>
              </BaseTabsNav>
            </WrapTabsNav>
            <TabsContent id="tab-1" active={active === '#tab-1'}>
              <WrapDepartmentList>
                <SideFilterBase>
                  <SideFilter
                    title="Filtrar"
                    searchPlaceholder="ex: segurança"
                    onSearchChange={(event) => setSearch(event.target.value)}
                    onSearchClick={onSearchClick}
                    switchData={[]}
                    dropdownData={[]}
                    active={activeFilter}
                    handleToggle={handleToggleFilter}
                    insideAccordion={isSmallScreenFilter ? true : false}
                  />
                </SideFilterBase>
                <WrapSecretariatsList>
                  <Title title="Secretarias" />
                  <Grid container spacing={3}>
                    {currentDepartments?.map((department) => (
                      <Grid
                        item
                        xs={12}
                        sm={isSmallScreen ? 12 : 6}
                        key={department.id}
                      >
                        <Accordion id={department.id}>
                          <AccordionTriggerStyled
                            onToggle={handleToggles}
                            active={actives.has(department.id)}
                          >
                            <AccordionTitle>{department.name}</AccordionTitle>
                            <AccordionSubtitleStyled
                              isActive={actives.has(department.id)}
                            >
                              {stripHtml(department.description || '')}
                            </AccordionSubtitleStyled>
                          </AccordionTriggerStyled>

                          <AccordionContent active={actives.has(department.id)}>
                            <BaseAccordionItem>
                              <AccordionItemStyled>
                                <ArticleIcon fontSize="small" />
                                {stripHtml(
                                  department.assignments || 'Não informado'
                                )}
                              </AccordionItemStyled>
                              <AccordionItemStyled>
                                <LocalPhoneIcon fontSize="small" />
                                {stripHtml(
                                  department.vision || 'Não informado'
                                )}
                              </AccordionItemStyled>
                              <AccordionItemStyled>
                                <LocationOnIcon fontSize="small" />
                                {stripHtml(
                                  department.values || 'Não informado'
                                )}
                              </AccordionItemStyled>
                            </BaseAccordionItem>
                            <BaseAccordionButton>
                              {department?.link && (
                                <Button
                                  onClick={() =>
                                    handleRedirect(department.link)
                                  }
                                  iconEnd={<ArrowRightAltIcon />}
                                  label="Ir para o site"
                                  size="none"
                                  variant="text"
                                />
                              )}
                            </BaseAccordionButton>
                          </AccordionContent>
                        </Accordion>
                      </Grid>
                    ))}
                    {departmentList && departmentList.length === 0 && (
                      <EmptyFilter>
                        <FilterAltOffIcon />
                        Nenhum resultado encontrado para os filtros
                        selecionados. Tente ajustar os critérios de busca.
                      </EmptyFilter>
                    )}
                  </Grid>
                  {totalPages && totalPages > 1 && (
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <EnumPagination
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                      />
                    </Grid>
                  )}
                </WrapSecretariatsList>
              </WrapDepartmentList>
            </TabsContent>
            <TabsContent id="tab-2" active={active === '#tab-2'}>
              <Governor />
            </TabsContent>
          </Tabs>
        </Container>
      </BaseDepartmentList>
    </>
  );
};
