import React, { FC, useCallback, useMemo } from 'react';
import { HomeSearch, HomeSearchProps } from './home-search';
import { useHistory } from 'react-router-dom';
import { useServicesFilters } from '../../hooks/use-services-filters';

export const HomeSearchContainer: FC = () => {
  const { setFilters } = useServicesFilters();
  const history = useHistory();

  const onClickSearch = useCallback(
    (value: string) => {
      setFilters((prev: any) => ({ ...prev, searchQuery: value }));
      if (!value) return;
      history.push(`search/${value}`);
    },
    [history, setFilters]
  );

  const imageBanner = useMemo(
    () =>
      `${process.env.PUBLIC_URL}/images/banner/${Math.floor(
        Math.random() * (3 - 1 + 1) + 1
      )}.png`,
    []
  );

  const props: HomeSearchProps = {
    onClickSearch,
    imageBanner,
  };

  return <HomeSearch {...props} />;
};
