import axios from 'axios';
import { generateBadgeCategory } from '../util/news';

const API_URL = process.env.API_NEWS_ENDPOINT;

interface RequestNewsVideoProps {
  data: NewsVideoProps[];
}

export interface NewsVideoProps {
  date_created: Date;
  date_updated: Date;
  description?: string;
  duration: string;
  id: number;
  status: string;
  thumbnail: string;
  title: string;
  url: string;
  user_created: string;
  user_updated: string;
}

interface RequestPostProps {
  data: PostProps[];
}

export interface PostProps {
  id: number;
  status: 'published' | 'draft' | 'archived';
  date_created: Date;
  date_updated?: Date;
  user_created: string;
  user_updated?: string;
  title: string;
  slug: string;
  content: string;
  categories: string[];
  thumbnail?: string;
  author: string;
}
export interface NewsCardProps {
  info: string;
  title: string;
  slug?: string;
  imageUrl?: string;
  onClick?: () => void;
  count?: number;
}

interface NewsFilter {
  category: string;
  limit: number;
  offset: number;
  slug?: string;
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function transformResponseToNewsCards(data: PostProps[]) {
  const newsCards: NewsCardProps[] = [];

  for (const post of data) {
    const newsCard: NewsCardProps = {
      title: post.title,
      info: generateBadgeCategory(post.categories),
      slug: post.slug,
    };

    if (post.thumbnail) {
      newsCard.imageUrl = `${API_URL}/assets/${post.thumbnail}`;
    }

    newsCards.push(newsCard);
  }

  return newsCards;
}

function loadThumbnail(data: PostProps[]) {
  for (const post of data) {
    if (post.thumbnail) {
      post.thumbnail = `${API_URL}/assets/${post.thumbnail}`;
    }
  }

  return data;
}

export async function getHomeNews(): Promise<NewsCardProps[]> {
  const response = await axiosInstance.get<RequestPostProps>(
    `/items/posts?filter[categories][_contains]=destaque&sort=-date_updated&filter[status][_eq]=published&limit=4`
  );
  const { data } = response.data;
  const newsCards = transformResponseToNewsCards(data);

  return newsCards;
}

export async function getNews(
  filter: Pick<NewsFilter, 'limit' | 'offset'>
): Promise<NewsCardProps[]> {
  const response = await axiosInstance.get<RequestPostProps>(
    `/items/posts?filter[status][_eq]=published&sort=-date_updated&limit=${filter.limit}&offset=${filter.offset}`
  );
  const { data } = response.data;
  const newsCards = transformResponseToNewsCards(data);

  return newsCards;
}

export async function getNewsVideo(): Promise<NewsVideoProps[]> {
  const response = await axiosInstance.get<RequestNewsVideoProps>(
    '/items/videos?filter[status][_eq]=published&sort=-date_created&limit=4'
  );
  const { data } = response.data;

  return data;
}

export async function getCountNews(): Promise<number> {
  const response = await axiosInstance.get(
    `/items/posts?filter[status][_eq]=published&sort=-date_updated&aggregate[count]=*`
  );
  const { data } = response.data;

  return data[0].count ?? 0;
}

export async function getNewsByCategory(
  filter: NewsFilter
): Promise<NewsCardProps[]> {
  const response = await axiosInstance.get<RequestPostProps>(
    `/items/posts?filter[status][_eq]=published&filter[categories][_contains]=${filter.category}&sort=-date_updated&limit=${filter.limit}&offset=${filter.offset}`
  );
  const { data } = response.data;
  const newsCards = transformResponseToNewsCards(data);

  return newsCards;
}

export async function getCountNewsByCategory(
  filter: Pick<NewsFilter, 'category'>
): Promise<number> {
  const response = await axiosInstance.get(
    `/items/posts?filter[status][_eq]=published&filter[categories][_contains]=${filter.category}&aggregate[count]=*`
  );
  const { data } = response.data;

  return data[0].count ?? 0;
}

export async function getNewsById(id: string): Promise<PostProps> {
  const response = await axiosInstance.get(
    `/items/posts/${id}/?filter[status][_eq]=published`
  );
  const { data } = response.data;
  const newsWithThumbnail = loadThumbnail(data);

  return newsWithThumbnail[0];
}

export async function getNewsBySlug(slug: string): Promise<PostProps> {
  const response = await axiosInstance.get(
    `/items/posts?filter[status][_eq]=published&filter[slug][_eq]=${slug}`
  );
  const { data } = response.data;
  const newsWithThumbnail = loadThumbnail(data);

  return newsWithThumbnail[0];
}

export async function getRelatedNewsBySlug(
  filter: Pick<NewsFilter, 'category' | 'limit' | 'offset' | 'slug'>
): Promise<NewsCardProps[]> {
  const response = await axiosInstance.get(
    `/items/posts?filter[status][_eq]=published&filter[slug][_neq]=${filter.slug}&filter[categories][_eq]=${filter.category}&limit=${filter.limit}`
  );
  const { data } = response.data;
  const newsCards = transformResponseToNewsCards(data);

  return newsCards;
}

export async function getAssetById(assetId: string): Promise<any> {
  const response = await axiosInstance.get(`/assets/${assetId}`);

  return response.data;
}
