import React, { FC, useState } from 'react';
import { Container } from '../../components/container/container';
import {
  BaseVideoList,
  BannerWrap,
  BaseButton,
  BaseTabsNav,
  BaseTitleVideo,
  BaseVideo,
  CardVideo,
  ListWrap,
  Marker,
  StyledGrid,
  TitleCardVideo,
  TitleVideo,
  WrapTabsNav,
} from './style';
import {
  TabsNav,
  TabsTrigger,
  TabsContent,
  Tabs,
  CardMedia,
  Button,
  Title,
} from '@plataformaxvia/design-system';
import { NewsCardProps, NewsVideoProps } from '../../apis/news-rss';
import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { HeaderTitle } from '../../components/header-title/header-title';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InboxIcon from '@mui/icons-material/Inbox';
import { useHistory } from 'react-router-dom';
import HideImageIcon from '@mui/icons-material/HideImage';
import { BannerSliderContainer } from '../banner-slider/banner-slider.container';
import { FeaturedNews, MainNews, Overlay, TextContainer } from '../news/style';
import { VideoPlayer } from '../../components/video-player/video-player';

interface NewsListProps {
  handleToggle: (hash: string) => void;
  tabActivated: string;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  tabs: { id: string; name: string }[];
  newsCards: NewsCardProps[];
  newsVideos: NewsVideoProps[];
  onClick: (slug: string) => void;
}

const GRID_RENDER_STEP = 7;

const NewsCard = ({
  newsCard,
  onClick,
}: {
  newsCard: NewsCardProps;
  onClick: (slug: string) => void;
}) => (
  <CardMedia
    info={newsCard.info}
    title={newsCard.title}
    imageUrl={newsCard.imageUrl}
    onClick={() => onClick(newsCard.slug || '')}
  />
);

const FeaturedNewsCard = ({
  newsCard,
  onClick,
}: {
  newsCard: NewsCardProps;
  onClick: (slug: string) => void;
}) => (
  <MainNews>
    <FeaturedNews imageUrl={newsCard.imageUrl}>
      {!newsCard.imageUrl && (
        <HideImageIcon fontSize="large" className="hideIcon" />
      )}
      <Overlay className="overlay" onClick={() => onClick(newsCard.slug || '')}>
        <TextContainer>
          <p>{newsCard.info}</p>
          {newsCard.title}
        </TextContainer>
      </Overlay>
    </FeaturedNews>
  </MainNews>
);

export const NewsFeatureList: FC<NewsListProps> = ({
  handleToggle,
  tabActivated,
  tabs,
  newsCards,
  onClick,
  newsVideos,
}) => {
  const theme = useTheme();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [selectedVideoTitle, setSelectedVideoTitle] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const isVideoSmallScreen = useMediaQuery(theme.breakpoints.down(980));
  const history = useHistory();

  return (
    <>
      <HeaderTitle title="Notícias" />
      <ListWrap>
        <Container>
          <Tabs>
            <WrapTabsNav>
              <BaseTabsNav>
                <TabsNav>
                  {tabs.map(({ id, name }) => (
                    <TabsTrigger
                      href={`#${id}`}
                      onToggle={handleToggle}
                      active={tabActivated === `#${id}`}
                      key={id}
                      style={{
                        backgroundColor: 'var(--color-background-tab-trigger) !important',
                        color: 'var(--color-body-1) !important',
                      }}
                    >
                      {name}
                    </TabsTrigger>
                  ))}
                </TabsNav>
              </BaseTabsNav>
            </WrapTabsNav>
            {tabs.map(({ id }) => (
              <TabsContent id={id} active={tabActivated === `#${id}`} key={id}>
                {newsCards.length === 0 ? (
                  <div style={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px',
                    color: 'var(--color-grey-80)',
                    fontSize: 'var(--font-title-h2)'
                  }}>
                    <InboxIcon sx={{ fontSize: 60, marginBottom: '16px' }} />
                    Não há notícias disponíveis nesta categoria
                  </div>
                ) : (
                  <StyledGrid
                    container
                    spacing={3}
                    sx={{
                      display: 'grid',
                      gridTemplateRows: `repeat(${isSmallScreen ? 1 : 3}, 1fr)`,
                      gridTemplateColumns: `repeat(${
                        isSmallScreen ? 1 : 2
                      }, 1fr)`,
                    }}
                  >
                    {newsCards.map((newsCard, index) =>
                      index % GRID_RENDER_STEP === 0 ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          key={index}
                          sx={{ gridRow: 'span 3' }}
                        >
                          <FeaturedNewsCard
                            newsCard={newsCard}
                            onClick={onClick}
                          />
                        </Grid>
                      ) : (
                        <Grid item key={index}>
                          <NewsCard newsCard={newsCard} onClick={onClick} />
                        </Grid>
                      )
                    )}
                  </StyledGrid>
                )}
              </TabsContent>
            ))}
          </Tabs>
          <BaseButton>
            <Button
              label="Mais notícias"
              size="large"
              iconEnd={<ArrowForwardIcon />}
              variant="primary"
              style={{ width: 'auto' }}
              onClick={() => history.push('todas-noticias')}
            />
          </BaseButton>
        </Container>
      </ListWrap>
      <BannerWrap>
        <BannerSliderContainer />
      </BannerWrap>
      {newsVideos && newsVideos.length > 0 && (
        <ListWrap>
          <Container>
            <Title title="Vídeos" />
            <BaseVideo isVideoSmallScreen={isVideoSmallScreen}>
              <Box>
                <VideoPlayer videoUrl={selectedVideoUrl || newsVideos[0].url} />
                <BaseTitleVideo>
                  <Marker />
                  <TitleVideo>
                    {selectedVideoTitle || newsVideos[0].title}
                  </TitleVideo>
                </BaseTitleVideo>
              </Box>
              <BaseVideoList>
                {newsVideos.map((video, index) => (
                  <CardVideo
                    key={video.id}
                    onClick={() => {
                      setSelectedVideoUrl(video.url);
                      setSelectedVideoTitle(video.title);
                    }}
                  >
                    <Box
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedVideoUrl(video.url);
                        setSelectedVideoTitle(video.title);
                      }}
                      sx={{
                        position: 'relative',
                        '& iframe': {
                          pointerEvents: 'none'
                        }
                      }}
                    >
                      <VideoPlayer videoUrl={video.url} />
                    </Box>
                    <TitleCardVideo>{video.title}</TitleCardVideo>
                  </CardVideo>
                ))}
              </BaseVideoList>
            </BaseVideo>
          </Container>
        </ListWrap>
      )}
    </>
  );
};
