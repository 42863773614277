import { FC } from 'react';
import { FacebookRounded, Instagram, Twitter } from '@mui/icons-material';
import { Footer, FooterProps } from './footer';
import { ROUTES } from '../../routes';

export const FooterContainer: FC = () => {
  const props: FooterProps = {
    social: [
      {
        Icon: FacebookRounded,
        link: 'https://www.facebook.com/scge.pe?_rdc=2&_rdr#',
      },
      {
        Icon: Twitter,
        link: 'https://x.com/governope?mx=2',
      },
      {
        Icon: Instagram,
        link: 'https://www.instagram.com/scge_pe/',
      },
    ],
    internalLinks: [
      {
        text: 'Início',
        link: `/app/catalog${ROUTES.HOME_PAGE}`,
      },
      {
        text: 'Nosso Governo',
        link: 'https://www.pe.gov.br/governo/',
      },
      {
        text: 'Secretarias e Órgãos',
        link: `/app/catalog${ROUTES.DEPARTMENT_LIST_PAGE}`,
      },
      {
        text: 'Carta de Serviços',
        link: `/app/catalog${ROUTES.SERVICE_LIST_PAGE}`,
      },
      {
        text: 'SIC - Serviços de Informação ao Cidadão',
        link: 'https://transparencia.pe.gov.br/participacao-cidada-pe/acesso-a-informacao/',
      },
      {
        text: 'Agenda do Governador',
        link: 'https://www.sds.pe.gov.br/9-imprensa/6-agenda-do-governador',
      },
    ],
    externalLinks: [
      {
        text: 'Diário Oficial',
        link: 'https://diariooficial.cepe.com.br/diariooficialweb/#/home?diario=MQ%3D%3D',
      },
      {
        text: 'Ouvidoria',
        link: '/app/ouvidoria',
      },
      {
        text: 'Portal de Transparência',
        link: 'https://transparencia.pe.gov.br/',
      },
      {
        text: 'LAI - Lei de Acesso à Informação',
        link: 'https://www.lai.pe.gov.br/',
      },
      {
        text: 'Painel de Licitações',
        link: 'http://www.licitacoes.pe.gov.br/web/ListaLicitacao.aspx',
      },
    ],
  };

  return <Footer {...props} />;
};
