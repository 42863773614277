import { Dialog, IconButton, styled } from '@mui/material';

export const XviaHomeSearch = styled('div')<{
  imageBanner: string | undefined;
}>(({ theme, imageBanner }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundSize: 'cover',
  height: '282px',
  backgroundImage: imageBanner
    ? `url(${imageBanner})`
    : 'var(--xvia-home-active-contrast)',
}));

export const HomeWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
  width: '65%',
  margin: 'auto auto',
  transition: 'width 0.8s ease-in-out',
  [theme.breakpoints.down(880)]: {
    width: '100%',
  },

  '& .label': {
    width: '100%',
    textAlign: 'center',
    fontSize: 'var(--font-title-h1)',
    fontWeight: '700',
    lineHeight: 'var(--line-height-title-h1)',
    color: 'var(--xvia-color-title-contrast)',
    fontFamily: 'var(--xvia-font-family)',
    transition: 'all 0.8s ease-in-out',
    [theme.breakpoints.down(880)]: {
      fontSize: '28px',
    },
    [theme.breakpoints.down(560)]: {
      fontSize: '24px',
    },
  },
}));

export const HomeSearchWrap = styled('div')(() => ({
  display: 'flex',
  gap: '2rem',
  width: '100%',
}));

export const HomeSearchInputWrap = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  flex: 1,
  height: '52px',
  gap: '8px',
  padding: '14px 24px',
  borderRadius: '8px',
  backgroundColor: 'var(--color-input-background)',
  border: 'var(--color-input-border)',
  fontFamily: 'var(--xvia-font-family)',
  cursor: 'text',

  '& svg': {
    color: 'var(--color-grey-90)',
  },
}));

export const HomeSearchInputLabel = styled('label')(() => ({
  fontSize: 'var(--font-body-1)',
  color: 'var(--color-grey-80)',
  fontWeight: 500,
}));

export const HeaderActionsDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(8px)',
  },
  '& .MuiPaper-root': {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
    top: 150,
    left: '45%',
    transform: 'translateX(-50%)',
    marginTop: 0,
    [theme.breakpoints.down(500)]: {
      left: '43%',
    },
    [theme.breakpoints.down(380)]: {
      left: '40%',
    },
  },
}));

export const HeaderActionsIconButton = styled(IconButton)(() => ({
  '&.search': {
    color: '#28272C',
    transition: 'color 0.5s ease-in-out',

    '&:hover': {
      color: '#0049A9',
    },
  },

  '&.menu': {
    color: '#0049A9',
  },

  '&.dialog': {
    position: 'absolute',
    right: 40,
    top: -10,
    color: '#FDFDFD',
  },
}));
