import React, {
  FC,
  MouseEventHandler,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CatalogDetail } from '../../apis/detail-catalog';
import { Container } from '../../components/container/container';
import { Step } from '../../components/step/step';
// @ts-ignore
import renderHTML from 'react-render-html';
import { Info } from '../../components/info/info';
import { Tag } from '../../components/tag/tag';
import {
  BreadcrumbsProps,
  Button,
  DepartmentSections,
  Option,
  ServiceLocation,
  Title,
} from '@plataformaxvia/design-system';
import { HeaderDetail } from '../../components/header-detail/header-detail';
import {
  AccordionDetailsInfo,
  AccordionDetailsStyled,
  AccordionMarker,
  AccordionStyled,
  AccordionSummaryStyled,
  BaseAccordion,
  CatalogButton,
  CatalogDetailStyled,
  CatalogTitle,
  CatalogTitleBase,
  DetailBase,
  DetailStyled,
  DividerStyled,
  ServiceLocationBase,
} from './style';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { stripHtml } from '../../util/html';
import { WarningModal } from '../../components/warning-modal/WarningModal';
import { ContainerModalAction } from '../../components/warning-modal/style';

export interface CatalogProps extends CatalogDetail {
  onPdfClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  breadcrumbs: BreadcrumbsProps;
  departamentLocation: DepartmentSections[];
  buttonLabel: string;
  departmentOption: Option | null;
  setDepartmentOption: (option: Option | null) => void;
  isRedirectWarningModalOpen?: boolean;
  toggleRedirectWarningModal: () => void;
  handleRedirectWarningModalConfirm: () => void;
  setCurrentServiceRedirectLink: (link: string) => void;
  mapUrl: string;
}

export const Catalog: FC<CatalogProps> = ({
  name,
  onPdfClick,
  onServiceClick,
  description,
  info,
  steps,
  tags,
  category,
  breadcrumbs,
  departamentLocation,
  buttonLabel,
  isRedirectWarningModalOpen = false,
  toggleRedirectWarningModal,
  handleRedirectWarningModalConfirm,
  setCurrentServiceRedirectLink,
  departmentOption,
  setDepartmentOption,
  mapUrl,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState<string[]>(['info', 'tag']);

  useEffect(() => {
    if (steps?.length > 0) {
      setExpanded((prev) => [...prev, ...steps.map((step) => step.id)]);
    }
  }, [steps]);

  const handleAccordionChange =
    (panel: string) => (_: any, isExpanded: boolean) => {
      setExpanded((prevExpanded) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((id) => id !== panel)
      );
    };
  return (
    <>
      <HeaderDetail breadcrumbs={breadcrumbs} />
      <DetailBase>
        <Container>
          <CatalogTitleBase>
            <CatalogTitle>
              <Title
                description={stripHtml(description)}
                iconInfo={
                  <FontAwesomeIcon
                    size="lg"
                    icon={category?.icon as IconProp}
                  />
                }
                info={category?.name}
                title={name}
              />
            </CatalogTitle>
            <CatalogButton>
              {buttonLabel && (
                <Button
                  iconStart={<CalendarTodayIcon />}
                  label={buttonLabel}
                  size="large"
                  onClick={onServiceClick}
                />
              )}
              <Button
                iconStart={<PictureAsPdfIcon />}
                label="Baixar como PDF"
                size="none"
                variant="text"
                onClick={onPdfClick}
              />
            </CatalogButton>
          </CatalogTitleBase>
          <DividerStyled />
          <CatalogDetailStyled>
            <DetailStyled>
              {steps?.length > 0 && (
                <>
                  {steps?.map((step, index) => {
                    return (
                      <>
                        <BaseAccordion
                          key={step.id}
                          isExpanded={expanded.includes(step.id)}
                        >
                          <AccordionMarker className="marker" />
                          <AccordionStyled
                            expanded={expanded.includes(step.id)}
                            onChange={handleAccordionChange(step.id)}
                            defaultExpanded
                          >
                            <AccordionSummaryStyled
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              {step?.channel === 'online' ? (
                                <span>
                                  Online - Canal de atendimento digital
                                </span>
                              ) : (
                                <span>Presencial - Unidades do governo</span>
                              )}
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                              <Step number={step.orderIndex} title={step?.name}>
                                {step?.description}
                                <DescriptionHTML
                                  description={step?.description}
                                  toggleRedirectWarningModal={
                                    toggleRedirectWarningModal
                                  }
                                  setCurrentServiceRedirectLink={
                                    setCurrentServiceRedirectLink
                                  }
                                />
                                <div>
                                  {step.documents.length > 0 && (
                                    <div>
                                      <h3>Documentos necessários</h3>
                                      {step.documents.map((doc, docIndex) => {
                                        return (
                                          <div key={docIndex}>
                                            <Info
                                              text={doc}
                                              icon={'fa-check'}
                                            />
                                          </div>
                                        );
                                      })}{' '}
                                    </div>
                                  )}
                                </div>
                              </Step>
                            </AccordionDetailsStyled>
                          </AccordionStyled>
                        </BaseAccordion>
                      </>
                    );
                  })}
                </>
              )}
              {info?.length > 0 && (
                <BaseAccordion isExpanded={expanded.includes('info')}>
                  <AccordionMarker className="marker" />
                  <AccordionStyled
                    expanded={expanded.includes('info')}
                    onChange={handleAccordionChange('info')}
                  >
                    <AccordionSummaryStyled
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={'Painel - Informação'}
                      id={'info'}
                    >
                      Informações
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled>
                      <AccordionDetailsInfo>
                        {info?.map((info) => (
                          <Step
                            key={info?.id}
                            icon={info?.icon}
                            title={info?.title}
                          >
                            <DescriptionHTML
                              description={info?.text}
                              toggleRedirectWarningModal={
                                toggleRedirectWarningModal
                              }
                              setCurrentServiceRedirectLink={
                                setCurrentServiceRedirectLink
                              }
                            />
                          </Step>
                        ))}
                      </AccordionDetailsInfo>
                    </AccordionDetailsStyled>
                  </AccordionStyled>
                </BaseAccordion>
              )}
              {tags?.length > 0 && (
                <BaseAccordion
                  key={'tag'}
                  isExpanded={expanded.includes('tag')}
                >
                  <AccordionMarker className="marker" />
                  <AccordionStyled
                    expanded={expanded.includes('tag')}
                    onChange={handleAccordionChange('tag')}
                  >
                    <AccordionSummaryStyled
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={'Painel - tag'}
                      id={'tag'}
                    >
                      Tags
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled>
                      {tags?.map((tag, tagIndex) => (
                        <Tag
                          key={tagIndex}
                          href={'#'}
                          aria-label={`Tag ${tag}`}
                        >
                          {tag}
                        </Tag>
                      ))}
                    </AccordionDetailsStyled>
                  </AccordionStyled>
                </BaseAccordion>
              )}
            </DetailStyled>
            <ServiceLocationBase>
              <ServiceLocation
                mapUrl={mapUrl}
                department={departmentOption}
                isOpen={isOpen}
                departmentSections={departamentLocation}
                setDepartment={setDepartmentOption}
                setIsOpen={setIsOpen}
                title="Buscar local de atendimento"
              />
            </ServiceLocationBase>
          </CatalogDetailStyled>
        </Container>
      </DetailBase>
      <WarningModal
        headerTitle="Atenção!"
        icon={<WarningIcon />}
        headerBgColor="#F5A623"
        onCloseModal={toggleRedirectWarningModal}
        modalOpen={isRedirectWarningModalOpen}
      >
        <p>
          Você está sendo redirecionado para um link externo. Tem certeza que
          deseja continuar?
        </p>

        <ContainerModalAction>
          <Button
            onClick={handleRedirectWarningModalConfirm}
            label="Confirmar redirecionamento"
          />
        </ContainerModalAction>
      </WarningModal>
    </>
  );
};

type DescriptionHTMLProps = Pick<
  CatalogProps,
  'description' | 'toggleRedirectWarningModal' | 'setCurrentServiceRedirectLink'
>;

const DescriptionHTML: React.FC<DescriptionHTMLProps> = ({
  description,
  toggleRedirectWarningModal,
  setCurrentServiceRedirectLink,
}) => {
  const descriptionHTMLRef = createRef<HTMLDivElement>();

  const handleDescriptionHTMLAnchorClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const anchor = event.target as HTMLAnchorElement;

      setCurrentServiceRedirectLink(anchor.href);
      toggleRedirectWarningModal();
    },
    [setCurrentServiceRedirectLink, toggleRedirectWarningModal]
  );

  useEffect(() => {
    if (!descriptionHTMLRef.current) {
      return;
    }

    const anchors = descriptionHTMLRef.current.querySelectorAll('a');

    anchors.forEach((anchor) => {
      anchor.target = '_blank';
      anchor.addEventListener('click', handleDescriptionHTMLAnchorClick);
    });

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleDescriptionHTMLAnchorClick);
      });
    };
  }, [descriptionHTMLRef, handleDescriptionHTMLAnchorClick]);

  return <div ref={descriptionHTMLRef}>{renderHTML(description)}</div>;
};
