import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const SocialMidiaContainer = styled(MuiBox)`
  display: flex;
`;

export const SocialLinkItem = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  color: #fdfdfd;
`;
