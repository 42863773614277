import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useWebgate } from '@xvia/webgate-connect';
import { getServerData } from '../util/get-server-data';
import { ICategory, getCategorys } from '../apis/categorys';
import { IDepartment, getDepartments } from '../apis/department';
import { Catalog } from '../apis/search-catalog';
import { getCatalog } from '../apis/relevant-catalog';
import { useRedirectWarningModal } from './use-redirect-warning-modal';
import { DropdownData } from '@plataformaxvia/design-system';
import { extractQuery } from '../util/url';

export interface IFilter {
  searchQuery: string;
  selectedCategory: string;
  selectedDepartment: string;
  digital: boolean;
  scheduled: boolean;
  currentPage: number;
}

type ServicesFiltersContextType = {
  selectedCategory: string;
  categories: ICategory[];
  departments: IDepartment[];
  selectedDepartment: string;
  switchData: {
    id: string;
    label: string;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
  }[];
  dropdownData: DropdownData[];
  servicesCatalogList: Catalog[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  element: React.ReactNode;
  totalServices: boolean;
  isRedirectWarningModalOpen: boolean;
  toggleRedirectWarningModal: () => void;
  handleRedirectWarningModalConfirm: () => void;
  handleInfoClick: (slug: string) => void;
  handleRequestClick: (link: string) => void;
  handleSearchClick: () => void;
  setFilters: React.Dispatch<React.SetStateAction<IFilter>>;
  filters: IFilter;
};

export const ServicesFiltersContext = createContext<ServicesFiltersContextType>(
  {} as ServicesFiltersContextType
);

export const ServicesFiltersContextProvider: React.FC<PropsWithChildren<{}>> =
  ({ children }) => {
    const { accessToken, serverData, portalConnect } = useWebgate();
    let history = useHistory();

    const [categories, setCategories] = useState<ICategory[]>([]);
    const [departments, setDepartments] = useState<IDepartment[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const [categoryDropdownVisibility, setCategoryDropdownVisibility] =
      useState(false);
    const [departmentDropdownVisibility, setDepartmentDropdownVisibility] =
      useState(false);
    const [servicesCatalogList, setServicesCatalogList] = useState<Catalog[]>(
      serverData?.top || getServerData('top', null)
    );

    const [filters, setFilters] = useState(() => {
      return {
        searchQuery: extractQuery(),
        selectedCategory: '',
        selectedDepartment: '',
        digital: false,
        scheduled: false,
        currentPage: 1,
      };
    });

    const uniqueCategories: ICategory[] = [];
    const seenSlugs = new Set<string>();
    const itemsPerPage = 8;

    categories.forEach((category) => {
      if (!seenSlugs.has(category.slug)) {
        seenSlugs.add(category.slug);
        uniqueCategories.push(category);
      }
    });

    const uniqueDepartments: IDepartment[] = [];

    departments.forEach((department) => {
      if (!seenSlugs.has(department.slug)) {
        seenSlugs.add(department.slug);
        uniqueDepartments.push(department);
      }
    });

    const createSwitchData = (scheduled: boolean, digital: boolean) => [
      {
        id: '1',
        label: 'Agendamento Online',
        checked: scheduled,
        setChecked: () =>
          setFilters({ ...filters, scheduled: !scheduled, currentPage: 1 }),
        disabled: true,
      },
      {
        id: '2',
        label: 'Serviço Digital',
        checked: digital,
        setChecked: () =>
          setFilters({ ...filters, digital: !digital, currentPage: 1 }),
        disabled: true,
      },
    ];

    const switchData = createSwitchData(filters.scheduled, filters.digital);

    const toggleCategorySelection = (slug: string) => {
      if (filters.selectedCategory === slug) {
        setFilters({ ...filters, selectedCategory: '', currentPage: 1 });
      } else {
        setFilters({ ...filters, selectedCategory: slug, currentPage: 1 });
      }
    };

    const toggleDepartmentSelection = (slug: string) => {
      if (filters.selectedDepartment === slug) {
        setFilters({ ...filters, selectedDepartment: '', currentPage: 1 });
      } else {
        setFilters({ ...filters, selectedDepartment: slug, currentPage: 1 });
      }
    };

    const formatLabel = (name: string) => {
      return name
        .split(' ')
        .map((word) =>
          word.length <= 2
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    };

    const dropdownData = [
      {
        id: 'categoria',
        title: 'Por Categoria',
        active: categoryDropdownVisibility,
        onToggle: () => setCategoryDropdownVisibility((prev) => !prev),
        dropdownItems: uniqueCategories.map((category) => ({
          id: category.id,
          label: formatLabel(category.name),
          checked: filters.selectedCategory === category.slug,
          setChecked: () => toggleCategorySelection(category.slug),
        })),
      },
      {
        id: 'departamento',
        title: 'Por Secretaria e Órgão',
        active: departmentDropdownVisibility,
        onToggle: () => setDepartmentDropdownVisibility((prev) => !prev),
        dropdownItems: uniqueDepartments.map((department) => ({
          id: department.id,
          label: formatLabel(department.name),
          checked: filters.selectedDepartment === department.slug,
          setChecked: () => toggleDepartmentSelection(department.slug),
        })),
      },
    ];

    const handleSearchClick = () => {
      setFilters({ ...filters, searchQuery: search, currentPage: 1 });
    };

    const getServices = useCallback(async () => {
      const from = (filters.currentPage - 1) * itemsPerPage;
      try {
        const result = await getCatalog(accessToken, {
          from,
          size: itemsPerPage,
          query: filters.searchQuery || undefined,
          category: filters.selectedCategory
            ? [filters.selectedCategory]
            : undefined,
          departmentSlug: filters.selectedDepartment
            ? [filters.selectedDepartment]
            : undefined,
          isDigital: filters.digital || undefined,
          isOnline: filters.scheduled || undefined,
        });
        setServicesCatalogList(result.items);
        setTotalPages(result.totalPages);
      } catch (err) {
        console.error(err);
      }
    }, [accessToken, filters]);

    const {
      toggleRedirectWarningModal,
      handleRedirectWarningModalConfirm,
      isRedirectWarningModalOpen,
      setCurrentServiceRedirectLink,
    } = useRedirectWarningModal();

    const handleRequestClick = useCallback(
      (link: string) => {
        if (!link.startsWith('/app')) {
          setCurrentServiceRedirectLink(link);
          toggleRedirectWarningModal();
        } else {
          portalConnect?.redirectTo(link);
        }
      },
      [toggleRedirectWarningModal, portalConnect, setCurrentServiceRedirectLink]
    );

    const handleInfoClick = useCallback(
      (slug) => {
        history.push(`/carta-de-servicos/${slug}`);
      },
      [history]
    );

    const getFiltersData = useCallback(async () => {
      try {
        const categories = await getCategorys();
        setCategories(categories);
        const departments = await getDepartments();
        setDepartments(departments);
      } catch (err) {
        console.error(err);
      }
    }, []);

    useEffect(() => {
      getFiltersData();
    }, [getFiltersData]);

    useEffect(() => {
      getServices();
    }, [getServices]);

    return (
      <ServicesFiltersContext.Provider
        value={{
          categories,
          selectedCategory: filters.selectedCategory,
          departments,
          selectedDepartment: filters.selectedDepartment,
          switchData,
          dropdownData,
          servicesCatalogList,
          setCurrentPage: (page) =>
            setFilters({ ...filters, currentPage: page } as any),
          currentPage: filters.currentPage,
          totalPages,
          setSearch,
          title: 'Carta de Serviços',
          element: null,
          totalServices: true,
          isRedirectWarningModalOpen,
          toggleRedirectWarningModal,
          handleRedirectWarningModalConfirm,
          handleInfoClick,
          handleRequestClick,
          handleSearchClick,
          setFilters,
          filters,
        }}
      >
        {children}
      </ServicesFiltersContext.Provider>
    );
  };

export const useServicesFilters = () => useContext(ServicesFiltersContext);
