export const isValidUrl = (url?: string) => {
  if (!url) {
    return false;
  }

  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export const extractQuery = () => {
  if (typeof window === 'undefined') return '';

  const match = window.location.pathname.match(/(?<=\/search\/)([^\/]+)/);

  return match ? decodeURI(match[0]) : '';
};
