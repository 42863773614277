import React, { FC } from "react";
import { Title } from "@plataformaxvia/design-system";
import { Container } from "../../components/container/container";
import { BaseHeaderTitle, GradientOverlay } from "./style";

export interface HeaderTitleProps {
  title: string;
}

export const HeaderTitle: FC<HeaderTitleProps> = ({ title }) => {
  return (
    <BaseHeaderTitle>
      <GradientOverlay />
      <Container>
        <Title size="large" title={title} color="grey10" />
      </Container>
    </BaseHeaderTitle>
  );
};
