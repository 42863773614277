import { FC, useEffect } from 'react';
import { FooterContainer } from '../modules/footer/footer.container';
import { NewsDetailContainer } from '../modules/news-detail/news-detail.container';
import { useLocation } from 'react-router-dom';

export const News: FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location]);

  return (
    <>
      <NewsDetailContainer />
      <FooterContainer />
    </>
  );
};
