import { useEffect, useState } from 'react'

export const VersionFlag = () => {
  const [version, setVersion] = useState('Carregando...')

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL || ''}/version.json`)
      .then((response) => response.json())
      .then((data) => setVersion(data.version))
      .catch(() => setVersion('Erro ao carregar versão'))
  }, [])

  return (
    <span
      style={{
        position: 'fixed',
        bottom: 80,
        left: 5,
        background: 'aliceblue',
        fontSize: 12,
        fontWeight: 600,
        padding: '2px 2px',
        pointerEvents: 'none',
        borderLeft: '4px solid blue',
        zIndex: 9999,
      }}
    >
      Catalog: {version}
    </span>
  )
}
