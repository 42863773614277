import { styled } from '@mui/material';

export const BannerSliderWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'transparent',
}));

export const SliderImage = styled('div')(() => ({
  padding: '0 5px',
  borderRadius: '10px',
  margin: 0,

  '& img': {
    width: '100%',
    borderRadius: '10px',
    height: '250px',
    objectFit: 'fill',
  },
}));
