import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getDepartments, IDepartment } from '../../apis/department';
import { DepartmentList, DepartmentProps } from './department-list';
import { capitalizeTitle } from '../../util/texts';

export const DepartmentListContainer: FC = () => {
  const [search, setSearch] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [departmentSlug, setDepartmentSlug] = useState<string[] | undefined>(
    undefined
  );

  const uniqueDepartments: IDepartment[] = [];
  const seenSlugs = new Set();

  const toggleCategorySelection = (slug: string) => {
    setSelectedCategories((prev) =>
      prev.includes(slug) ? prev.filter((cat) => cat !== slug) : [...prev, slug]
    );
  };

  const toggleDropdownVisibility = () => {
    setDropdownVisibility((prev) => !prev);
  };

  departments.forEach((department) => {
    if (!seenSlugs.has(department.slug)) {
      seenSlugs.add(department.slug);
      uniqueDepartments.push(department);
    }
  });

  const dropdownData = [
    {
      id: 'categoria',
      title: 'Categoria',
      active: dropdownVisibility,
      onToggle: () => toggleDropdownVisibility(),
      dropdownItems: uniqueDepartments.map((department) => ({
        id: department.id,
        label: department.name,
        checked: selectedCategories.includes(department.slug),
        setChecked: () => toggleCategorySelection(department.slug),
      })),
    },
  ];

  const handleSearchClick = () => {
    setSearchQuery(search);
    setDepartmentSlug(selectedCategories);
  };

  const filteredDepartments = useMemo(() => {
    return departments.filter((department) => {
      const matchesSearchQuery = searchQuery
        ? department.name.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      const matchesDepartmentSlug = departmentSlug?.length
        ? departmentSlug.includes(department.slug)
        : true;

      return matchesSearchQuery && matchesDepartmentSlug;
    });
  }, [departments, searchQuery, departmentSlug]);

  const getDepartment = useCallback(async () => {
    const transformDepartments = (departments: IDepartment[]) => {
      return departments.map((department) => ({
        ...department,
        name: capitalizeTitle(department.name),
      }));
    };

    try {
      const list = await getDepartments();
      const transformedDepartments = transformDepartments(list);

      setDepartments(transformedDepartments as IDepartment[]);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getDepartment();
  }, [getDepartment]);

  const newsProps: DepartmentProps = {
    departmentList: filteredDepartments,
    setSearch,
    onSearchClick: handleSearchClick,
    dropdownData,
  };

  return createElement(DepartmentList, newsProps);
};
