import { useCallback, useState } from "react";

export const useRedirectWarningModal = () => {
    const [isRedirectWarningModalOpen, setIsRedirectWarningModalOpen] = useState(false);
    const [currentServiceRedirectLink, setCurrentServiceRedirectLink] = useState('');

    const toggleRedirectWarningModal = useCallback(() => {
        setIsRedirectWarningModalOpen(!isRedirectWarningModalOpen);
    }, [isRedirectWarningModalOpen]);

    const handleRedirectWarningModalConfirm = useCallback(() => {
        toggleRedirectWarningModal();

        const correctedLink = /^(https?:\/\/)/i.test(currentServiceRedirectLink)
        ? currentServiceRedirectLink
        : `https://${currentServiceRedirectLink}`;
      window.open(correctedLink, '_blank');

    }, [currentServiceRedirectLink, toggleRedirectWarningModal]);

    return {
        isRedirectWarningModalOpen,
        toggleRedirectWarningModal,
        handleRedirectWarningModalConfirm,
        setCurrentServiceRedirectLink,
    }
};