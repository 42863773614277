import { FC } from 'react';
import { Container } from '../../components/container/container';
import {
  AuthorStyled,
  DetailWrap,
  DividerStyled,
  RelatedWrap,
  StyledBoxGrid,
  StyledGrid,
} from './style';
import { HeaderDetail } from '../../components/header-detail/header-detail';

import { BreadcrumbItem } from '../../components/breadcrumb/breadcrumb';
import { CardMedia, Title } from '@plataformaxvia/design-system';
import { NewsCardProps } from '../../apis/news-rss';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

export interface NewsDetailProps {
  title: string;
  category: string;
  content: string;
  thumbnail: string;
  author: string;
  breadcrumb: BreadcrumbItem[];
  relatedNews?: NewsCardProps[];
  onClick: (slug: string) => void;
}

export const NewsDetail: FC<NewsDetailProps> = ({
  title,
  category,
  content,
  thumbnail,
  breadcrumb,
  relatedNews,
  author,
  onClick,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <>
      <HeaderDetail breadcrumbs={{ items: breadcrumb }} />
      <DetailWrap>
        <Container>
          <StyledGrid container spacing={3}>
            <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
              <Title title={title} info={category} />
              <StyledBoxGrid dangerouslySetInnerHTML={{ __html: content }} />
            </Grid>
            <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
              <img
                src={thumbnail}
                alt="Imagem em miniatura da notícia (Thumbnail)"
                width="100%"
              />
              <DividerStyled />
              <AuthorStyled>
                <PersonOutlineIcon />
                {author}
              </AuthorStyled>
            </Grid>
          </StyledGrid>
        </Container>
      </DetailWrap>
      <RelatedWrap>
        <Container>
          <Title title="Outras notícias" />
          <StyledGrid container spacing={3} sx={{ marginTop: '16px' }}>
            {relatedNews?.map((news) => (
              <Grid item xs={12} sm={isSmallScreen ? 12 : 6}>
                <CardMedia
                  title={news.title}
                  info={news.info}
                  imageUrl={news.imageUrl}
                  onClick={() => onClick(news.slug || '')}
                />
              </Grid>
            ))}
          </StyledGrid>
        </Container>
      </RelatedWrap>
    </>
  );
};
