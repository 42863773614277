import { styled } from "@mui/material";

export const IndicatorsWrap = styled("div")(() => ({
  padding: "56px 0px",
  backgroundColor: "var(--color-background-dark)",
}));

export const IndicatorsContentWrap = styled("div")(() => ({
  display: "flex",
  gap: "24px",
  flexDirection: "column",
  alignItems: "center",
}));

export const IndicatorsTitle = styled("div")(() => ({
  fontSize: "var(--font-title-h2)",
  lineHeight: "var(--line-height-title-h2)",
  color: "var(--color-grey-10)",
  fontWeight: 700
}));

export const IndicatorsBase = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "32px",
  color: "var(--color-grey-10)",
  alignItems: "center",
  [theme.breakpoints.down(900)]: {
    flexDirection:"column"
  },
}));

export const IndicatorsInfo = styled("div")(() => ({
  display: "flex",
  gap: "16px",
  alignItems: "center"
}));

export const IconIndicators = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "80px",
  height: "80px",
  backgroundColor: "var(--color-secondary)",
  borderRadius: "50%"
}));

export const IndicatorsMetric = styled("div")(() => ({
  fontSize: "var(--font-title-h1)",
  lineHeight: "var(--line-height-title-h1)",
  fontWeight: 700
}));

export const IndicatorsCategory = styled("div")(() => ({
  fontSize: "var(--font-subtitle-1)",
  lineHeight: "24px",
  fontWeight: 300
}));

export const MarkerIndicators = styled("div")(({ theme }) => ({
  width: "1px",
  height: "50px",
  backgroundColor: "var(--color-grey-40)",
  opacity: 0.6,  
  [theme.breakpoints.down(900)]: {
    display:"none"
  },
}));