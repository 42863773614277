import { Grid, styled } from '@mui/material';

export const TopServicesWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const TopServicesBase = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(780)]: {
    flexDirection: 'column',
  },
}));

export const SideFilterBase = styled('div')(({ theme }) => ({
  width: '384px',
  transition: 'all 0.8s ease-in-out',
  [theme.breakpoints.down(780)]: {
    width: '100%',

    '& article': {
      maxWidth: '100%',
    },
  },
}));

export const ServicesBase = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  flex: 1,
}));

export const ServicesTitleBase = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  width: '100%',
}));

export const StyledGridServiceCard = styled(Grid)(() => ({
  width: '100%',
}));

export const EmptyFilter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '0px 32px',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'var(--font-title-h2)',
  color: 'var(--color-grey-80)',
}));

export const TotalServices = styled('div')(() => ({
  fontSize: 'var(--font-body-1)',
  color: 'var(--color-title-contrast)',
  fontWeight: 500,
  marginBottom: '35px',
}));
