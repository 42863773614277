import { FC, useCallback, useEffect, useState } from 'react';
import { News, NewsProps } from './news';
import { getHomeNews } from '../../apis/news-rss';
import { useHistory } from 'react-router-dom';

export const NewsContainer: FC = () => {
  let history = useHistory();
  const [newsList, setNewsList] = useState<any[]>([]);

  const getServerNews = useCallback(() => {
    getHomeNews()
      .then((result) => {
        setNewsList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/noticias/${slug}`);
    },
    [history]
  );

  useEffect(() => {
    getServerNews();
  }, [getServerNews]);

  const newsProps: NewsProps = {
    news: newsList?.slice(0, 4).map((catalog) => {
      return {
        title: catalog.title,
        info: catalog.info,
        onClick: () => {
          onClickCard(catalog.slug);
        },
        imageUrl: catalog.imageUrl,
      };
    }),
  };

  return <News {...newsProps} />;
};
