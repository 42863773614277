import React, { FC, useState } from 'react';
import { SearchInput } from '../../components/search-input/search-input';
import { Container } from '../../components/container/container';
import {
  HeaderActionsDialog,
  HeaderActionsIconButton,
  HomeSearchInputLabel,
  HomeSearchInputWrap,
  HomeSearchWrap,
  HomeWrap,
  XviaHomeSearch,
} from './style';
import SearchIcon from '@mui/icons-material/Search';
import { DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@plataformaxvia/design-system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
export interface HomeSearchProps {
  imageBanner?: string;
  onClickSearch: any;
}
export const HomeSearch: FC<HomeSearchProps> = ({
  onClickSearch,
  imageBanner,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(420));
  const [open, setOpen] = useState(false);

  const SearchDialog = ({ open }: { open: boolean }) => {
    return (
      <HeaderActionsDialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>
          <HeaderActionsIconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            disableRipple
            className="dialog"
          >
            <CloseIcon />
          </HeaderActionsIconButton>
        </DialogTitle>
        <DialogContent sx={{ marginTop: '20px' }}>
          <SearchInput
            className="xvia-home-search__input"
            placeholder={isSmallScreen ? 'Pesquisar...' : 'O que você procura?'}
            onClickSearch={onClickSearch}
            aria-label="Campo de busca de serviços"
          />
        </DialogContent>
      </HeaderActionsDialog>
    );
  };

  return (
    <XviaHomeSearch
      aria-label="Área de busca de serviços"
      imageBanner={imageBanner}
    >
      <Container className="xvia-home-search__container">
        <HomeWrap>
          <div className="label">
            Olá, você está no Portal de Pernambuco
          </div>
          <HomeSearchWrap onClick={() => setOpen(true)}>
            <HomeSearchInputWrap>
              <SearchIcon />
              <HomeSearchInputLabel>
                {isSmallScreen ? 'Pesquisar...' : 'O que você procura?'}
              </HomeSearchInputLabel>
            </HomeSearchInputWrap>
            <Button
              label={isSmallScreen ? '' : 'Pesquisar'}
              iconStart={isSmallScreen ? <SearchIcon /> : ''}
              size="large"
            />
          </HomeSearchWrap>
          <SearchDialog open={open} />
        </HomeWrap>
      </Container>
    </XviaHomeSearch>
  );
};
