import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useWebgate } from '@xvia/webgate-connect';
import { getServerData } from '../../util/get-server-data';
import { CatalogDetail, getCatalog } from '../../apis/detail-catalog';
import { Catalog, CatalogProps } from './catalog';
import { useParams } from 'react-router-dom';
import { getDateVerbose as getMaskDateVerbose } from '../../util/mask';
import { message } from 'antd';
import { DepartmentSections, Option } from '@plataformaxvia/design-system';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRedirectWarningModal } from '../../hooks/use-redirect-warning-modal';
import { searchAddressCoordinates } from '../../apis/map';

const getDateVerbose = (date?: string) =>
  date && getMaskDateVerbose(new Date(date).getTime());

const useCatalogDetail = (service: string, accessToken: string) => {
  const [catalogDetail, setCatalogDetail] = useState<CatalogDetail>();

  const getDetail = useCallback(
    (slug: string) => {
      getCatalog(accessToken, slug)
        .then((catalog) => {
          if (!catalog.active) throw new Error();
          setCatalogDetail(catalog);
        })
        .catch(() => {
          message.error('Não foi possível carregar as informações do serviço!');
        });
    },
    [accessToken]
  );

  useLayoutEffect(() => {
    const catalogDetailFromCache = getServerData('catalogDetail', null);

    if (catalogDetailFromCache?.slug === service) {
      setCatalogDetail(catalogDetailFromCache);
      return;
    }

    getDetail(service);
  }, [service, getDetail]);

  return { catalogDetail, setCatalogDetail };
};

const useDepartmentLocation = (catalogDetail: CatalogDetail | undefined) => {
  const [departmentOption, setDepartmentOption] = useState<Option | null>(null);
  const [departamentLocation, setDepartamentLocation] = useState<
    DepartmentSections[]
  >([]);
  const [mapUrl, setMapUrl] = useState<string>('');

  const getCoordinates = useCallback((address: string) => {
    searchAddressCoordinates(address)
      .then((coordinates) => {
        if (coordinates.length === 0) {
          setMapUrl('');
          return;
        }

        const { lat, lon } = coordinates[0];
        setMapUrl(
          `https://www.google.com/maps?q=${lat},${lon}&z=15&output=embed`
        );
      })
      .catch(() => {
        console.log('Não foi possível encontrar as informações geográficas do endereço.');
      });
  }, []);

  useEffect(() => {
    if (!catalogDetail) return;

    const { departmentSections } = catalogDetail;

    if (departmentSections.length > 0) {
      const newDepartmentSections = departmentSections
        .filter((dep) => dep.name?.trim())
        .sort((a, b) => {
          const nameA = a.name.trim();
          const nameB = b.name.trim();
          const regex = /^\d+/;
          const numA = nameA.match(regex);
          const numB = nameB.match(regex);

          if (numA && numB) {
            return parseInt(numA[0], 10) - parseInt(numB[0], 10);
          }

          return nameA.localeCompare(nameB);
        })
        .map((dep) => ({
          id: dep.id ?? '',
          name: dep.name.trim(),
          address: dep.address ?? '',
          postalCode: dep.postalCode ?? '',
          district: dep.district ?? '',
          city: dep.city ?? '',
          state: dep.state ?? '',
          countryCode: dep.countryCode ?? '',
          openingTime: dep.openingTime ?? '',
          mapUrl: dep.mapUrl ?? '',
          active: dep.active ?? false,
          complement: dep.complement ?? '',
        }));

      setDepartamentLocation(newDepartmentSections);
    }
  }, [catalogDetail]);

  useEffect(() => {
    if (!departmentOption?.value) return;

    const selectedDepartment = departamentLocation.find(
      (dept) => dept.id === departmentOption.value
    );

    if (!selectedDepartment?.address) return;

    const formatAddress = () => {
      const { address, complement, district, city, state, postalCode } =
        selectedDepartment;
      const complementText = complement ? `, ${complement}` : '';
      return `${address}${complementText}, ${district}, ${city} / ${state}, ${postalCode}`;
    };

    getCoordinates(formatAddress());
  }, [departmentOption, departamentLocation, getCoordinates]);

  return { departmentOption, setDepartmentOption, departamentLocation, mapUrl };
};

const useBreadcrumbs = (catalogDetail: CatalogDetail | undefined) => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  useEffect(() => {
    if (!catalogDetail) return;

    setBreadcrumbs([
      { name: 'Início', path: '/' },
      { name: 'Serviços', path: '/app/catalog/carta-de-servicos' },
      { name: catalogDetail.name },
    ]);

    return () => setBreadcrumbs([]);
  }, [catalogDetail]);

  return {
    breadcrumbsData: {
      items: breadcrumbs,
      separator: <KeyboardArrowRightIcon fontSize="small" />,
    },
  };
};

export const CatalogContainer: FC = () => {
  const { accessToken, portalConnect } = useWebgate();
  const { service } = useParams<{ service: string }>();
  const { catalogDetail } = useCatalogDetail(service, accessToken ?? '');
  const { departmentOption, setDepartmentOption, departamentLocation, mapUrl } =
    useDepartmentLocation(catalogDetail);
  const { breadcrumbsData } = useBreadcrumbs(catalogDetail);
  const {
    isRedirectWarningModalOpen,
    toggleRedirectWarningModal,
    handleRedirectWarningModalConfirm,
    setCurrentServiceRedirectLink,
  } = useRedirectWarningModal();

  const isValidHttpUrl = (str: string) => /^https?:\/\//.test(str);
  const isAppAgendamento = (str: string) =>
    str.startsWith('/app') && str.includes('agendamento-servicos');
  const isAppAcessar = (str: string) =>
    str.startsWith('/app') && !str.includes('agendamento-servicos');

  const getButtonLabel = (link?: string) => {
    if (!link) return null;
    if (isValidHttpUrl(link)) return 'Acessar';
    if (isAppAgendamento(link)) return 'Agendar';
    if (isAppAcessar(link)) return 'Acessar';
    return null;
  };

  const openLink = useCallback(
    (url) => {
      if (!url) return;
      isValidHttpUrl(url)
        ? window.open(url, '_blank')
        : portalConnect?.redirectTo(url);
    },
    [portalConnect]
  );

  const props = {
    ...(catalogDetail as CatalogProps),
    updated_at: getDateVerbose(catalogDetail?.updated_at),
    breadcrumbs: breadcrumbsData,
    departmentOption,
    setDepartmentOption,
    onPdfClick: () =>
      window.open(`/v1/catalog/${catalogDetail?.slug}/pdf`, '_blank'),
    onServiceClick: () => openLink(catalogDetail?.link),
    departamentLocation,
    buttonLabel: getButtonLabel(catalogDetail?.link),
    isRedirectWarningModalOpen,
    toggleRedirectWarningModal,
    handleRedirectWarningModalConfirm,
    setCurrentServiceRedirectLink,
    mapUrl,
  } as CatalogProps;

  return createElement(Catalog, props);
};
