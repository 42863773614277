import React, { FC } from 'react';
import {
  TabsNav,
  TabsTrigger,
  TabsContent,
  EnumPagination,
  Tabs,
  CardMedia,
} from '@plataformaxvia/design-system';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import HideImageIcon from '@mui/icons-material/HideImage';
import InboxIcon from '@mui/icons-material/Inbox';
import { Container } from '../../components/container/container';
import { BaseTabsNav, ListWrap, StyledGrid, StyledGridPagination, WrapTabsNav } from './style';
import { HeaderDetail } from '../../components/header-detail/header-detail';
import { NewsCardProps } from '../../apis/news-rss';
import { FeaturedNews, MainNews, Overlay, TextContainer } from '../news/style';

export interface NewsListProps {
  handleToggle: (hash: string) => void;
  tabActivated: string;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  tabs: { id: string; name: string }[];
  newsCards: NewsCardProps[];
  onClick: (slug: string) => void;
}

const GRID_RENDER_STEP = 7;

export const NewsList: FC<NewsListProps> = ({
  handleToggle,
  tabActivated,
  currentPage,
  totalPages,
  onPageChange,
  tabs,
  newsCards,
  onClick,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <>
      <HeaderDetail
        breadcrumbs={{
          items: [
            { name: 'Início', path: '/' },
            { name: 'Notícias', path: '/app/catalog/noticias' },
            { name: 'Todas as Notícias' },
          ],
        }}
      />
      <ListWrap>
        <Container>
          <Tabs>
            <WrapTabsNav>
              <BaseTabsNav>
                <TabsNav>
                  {tabs.map(({ id, name }) => (
                    <TabsTrigger
                      href={`#${id}`}
                      onToggle={handleToggle}
                      active={tabActivated === `#${id}`}
                      key={id}
                    >
                      {name}
                    </TabsTrigger>
                  ))}
                </TabsNav>
              </BaseTabsNav>
            </WrapTabsNav>
            {tabs.map(({ id }) => (
              <TabsContent id={id} active={tabActivated === `#${id}`} key={id}>
                {newsCards.length === 0 ? (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px',
                    color: 'var(--color-grey-80)',
                    fontSize: 'var(--font-title-h2)'
                  }}>
                    <InboxIcon sx={{ fontSize: 60, marginBottom: '16px' }} />
                    Não há notícias disponíveis nesta categoria
                  </div>
                ) : (
                  <StyledGrid
                    container
                    spacing={3}
                    sx={{
                      display: 'grid',
                      gridTemplateRows: `repeat(${isSmallScreen ? 1 : 3}, 1fr)`,
                      gridTemplateColumns: `repeat(${
                        isSmallScreen ? 1 : 2
                      }, 1fr)`,
                    }}
                  >
                    {newsCards.map((newsCard, index) =>
                      index % GRID_RENDER_STEP === 0 ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            key={index}
                            sx={{ gridRow: 'span 3' }}
                          >
                            <MainNews>
                              <FeaturedNews imageUrl={newsCard.imageUrl}>
                                {!newsCard.imageUrl && (
                                  <HideImageIcon
                                    fontSize="large"
                                    className="hideIcon"
                                  />
                                )}
                                <Overlay
                                  className="overlay"
                                  onClick={() => onClick(newsCard?.slug ?? '')}
                                >
                                  <TextContainer>
                                    <p>{newsCard.info}</p>
                                    {newsCard.title}
                                  </TextContainer>
                                </Overlay>
                              </FeaturedNews>
                            </MainNews>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={12} key={index}>
                            <CardMedia
                              key={index}
                              info={newsCard.info}
                              title={newsCard.title}
                              imageUrl={newsCard.imageUrl}
                              onClick={() => onClick(newsCard.slug || '')}
                            />
                          </Grid>
                        </>
                      )
                    )}
                  </StyledGrid>
                )}
              </TabsContent>
            ))}
          </Tabs>
          {totalPages > 1 && (
            <StyledGridPagination>
              <EnumPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                setCurrentPage={onPageChange}
                totalPages={totalPages}
              />
            </StyledGridPagination>
          )}
        </Container>
      </ListWrap>
    </>
  );
};
