import { FC } from 'react';
import { Container } from '../../components/container/container';
import Carousel from '../../components/Carousel';
import { BannerSliderWrap, SliderImage } from './style';

interface Banners {
  img: string;
  alt: string;
}

export interface BannerSliderProps {
  banners: Banners[];
}

export const BannerSlider: FC<BannerSliderProps> = ({ banners }) => {
  return (
    <BannerSliderWrap>
      <Container className="banner-slider">
        <Carousel
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          autoplay={true}
          speed={5000}
        >
          {banners.map((banners, index) => {
            return (
              <div key={index}>
                <SliderImage>
                  <img src={banners.img} alt={banners.alt} />
                </SliderImage>
              </div>
            );
          })}
        </Carousel>
      </Container>
    </BannerSliderWrap>
  );
};
