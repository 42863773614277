import React, { FC } from "react";
import { Container } from "../../components/container/container";
import {
  IconIndicators,
  IndicatorsBase,
  IndicatorsCategory,
  IndicatorsContentWrap,
  IndicatorsInfo,
  IndicatorsMetric,
  IndicatorsTitle,
  IndicatorsWrap,
  MarkerIndicators,
} from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface Indicator {
  icon?: string;
  value: number;
  detail: string;
}

export interface IndicatorsProps {
  indicators: Indicator[];
}

export const Indicators: FC<IndicatorsProps> = ({ indicators }) => {
  return (
    <IndicatorsWrap>
      <Container>
        <IndicatorsContentWrap>
          <IndicatorsTitle>Indicadores dos nossos serviços</IndicatorsTitle>
          <IndicatorsBase>
            {indicators &&
              indicators.length > 0 &&
              indicators.map((indicator, index) => (
                <React.Fragment key={index}>
                  <IndicatorsInfo>
                    <IconIndicators>
                      <FontAwesomeIcon size="2x" icon={indicator?.icon as IconProp} />
                    </IconIndicators>
                    <div>
                      <IndicatorsMetric>{indicator.value}</IndicatorsMetric>
                      <IndicatorsCategory>
                        {indicator.detail}
                      </IndicatorsCategory>
                    </div>
                  </IndicatorsInfo>
                  {index < indicators.length - 1 && <MarkerIndicators />}
                </React.Fragment>
              ))}
          </IndicatorsBase>
        </IndicatorsContentWrap>
      </Container>
    </IndicatorsWrap>
  );
};
