import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';
import { IconButton } from '@mui/material';

export const ContainerBgModal = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'modalOpen',
})<{ modalOpen: boolean }>(({ theme, modalOpen }) => ({
  position: 'fixed',
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  left: 0,
  top: 0,
  padding: '100px',
  transition: 'all 0.5s ease-in-out',
  opacity: modalOpen ? 1 : 0,
  visibility: modalOpen ? 'visible' : 'collapse',
  transitionDelay: modalOpen ? '0s, 0.2s' : '0s',
  zIndex: 999,
}));

export const ContainerModal = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'modalOpen',
})<{ modalOpen: boolean }>(({ theme, modalOpen }) => ({
  display: 'block',
  maxWidth: '600px',
  margin: '0 auto',
  transition: 'all 0.5s ease-in-out',
  opacity: modalOpen ? 1 : 0,
  transform: modalOpen ? 'translateY(0)' : 'translateY(-100vh)',
}));

export const ContainerHeaderModal = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '20px 30px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',

  '> svg': {
    width: '54px',
    height: '54px',
    color: 'var(--color-text-card-warning-header)',
  },
}));

export const ContainerHeaderTitle = styled(MuiBox)(({ theme }) => ({
  display: 'block',
  margin: '0 0 0 15px',

  '> h3': {
    fontSize: 'var(--font-title-h1)',
    fontWeight: '700',
    fontFamily: 'Roboto',
    margin: 0,
    color: 'var(--color-text-card-warning-header)',
  },
}));

export const MIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',

  '> svg': {
    color: 'var(--color-text-card-warning-header)',
  },
}));

export const ContainerContentModal = styled(MuiBox)(({ theme }) => ({
  display: 'block',
  backgroundColor: 'var(--color-background-card-warning-content)',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',

  '> p': {
    color: 'var(--color-text-card-warning-content)',
    padding: '40px',
    fontWeight: '700',
    fontSize: 'var(--font-body-1)',
  },
}));

export const ContainerModalAction = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 0 20px 0',
  backgroundColor: 'var(--color-background-card-warning-content)',
}));
