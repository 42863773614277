import { FC, useCallback, useEffect, useState } from "react";
import { RelevantServices, RelevantServicesProps } from "./relevant-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { getRelevant } from "../../apis/relevant-catalog";
import { useHistory } from "react-router-dom";

export const RelevantServicesContainer: FC = () => {
  const { accessToken, serverData } = useWebgate();
  let history = useHistory();
  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );

  const getRandomImageUrl = (index: number) => {
    const imageNumber = (index % 4) + 1;
    return `${process.env.PUBLIC_URL}/images/card/${imageNumber}.png`;
  };

  const getServerRelevant = useCallback(() => {
    getRelevant(accessToken)
      .then((result) => {
        setRelevantCatalogList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [accessToken]);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`carta-de-servicos/${slug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!relevantCatalogList?.length) getServerRelevant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog, index) => {
      return {
        info: catalog.targets.join(', '),
        title: catalog.title,
        imageUrl: getRandomImageUrl(index),
        description: catalog.description,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.slug);
        },
      };
    }),
  };

  return <RelevantServices {...relevantServicesProps} />;
};
