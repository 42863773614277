import { styled } from '@mui/material';

export const BaseVideoPlayer = styled('div')(() => ({
  width: '100%',
  maxWidth: '1920px',
  backgroundColor: 'transparent',
}));

export const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '16 / 9',
  borderRadius: '8px',
  border: '0px',
}));