import React, { FC } from 'react';
//@ts-ignore
import { Element } from 'react-scroll';
import { HomeSearchContainer } from '../modules/home-search/home-search.container';
import { RelevantServicesContainer } from '../modules/relevant-services/relevant-services.container';
import { NewsContainer } from '../modules/news/news.container';
import { FooterContainer } from '../modules/footer/footer.container';
import { IndicatorsContainer } from '../modules/indicators/indicators.container';
import { ServicesContainer } from '../modules/services/services.container';
import { BannerSliderContainer } from '../modules/banner-slider/banner-slider.container';
import { Button } from '@plataformaxvia/design-system';
import { useHistory } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export const Home: FC = () => {
  let history = useHistory();
  return (
    <>
      <Element name="HomeSearch">
        <HomeSearchContainer />
      </Element>

      <Element name="RelevantServices">
        <RelevantServicesContainer />
      </Element>

      <Element name="Banners" className="bgPrimary">
        <BannerSliderContainer />
      </Element>

      <Element name="newsContainer">
        <NewsContainer />
      </Element>

      <Element name="indicators">
        <IndicatorsContainer />
      </Element>

      <Element name="services">
        <ServicesContainer
          title="Serviços disponíveis"
          element={
            <Button
              iconEnd={<ArrowRightAltIcon />}
              label="Mais serviços"
              onClick={() => history.push('carta-de-servicos')}
              variant="primary"
              size="large"
            />
          }
          totalServices={false}
        />
      </Element>
      <FooterContainer />
    </>
  );
};
