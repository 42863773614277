import { styled } from '@mui/material';

export const SearchInputWrap = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const SearchInputContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  color: '#28272C',
  width: '100%',

  '& svg': {
    margin: '0 5px 0 20px',
    color: 'var(--xvia-header-icon-search-contrast)',
  },
}));

export const SearchInputStyled = styled('input')(() => ({
  width: '100%',
  paddingLeft: '10px',
  fontSize: '16px',
  color: '#28272C',
  background: 'none',
  border: 'none',
}));

export const SearchButtonWrapper = styled('div')(() => ({
  height: '44px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff99',
  justifyContent: 'center',
  borderRadius: '8px',
  padding: '0 5px',

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--color-button-primary)',
    border: 'none',
    padding: '6px 24px',
    borderRadius: '8px',
    color: 'var(--color-button-text-contrast)',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out 0s',
  },

  '& button:hover': {
    backgroundColor: 'var(--color-button-primary-hover)',
  },
}));
