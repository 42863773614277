import { useEffect } from 'react';
import './App.css';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { routes } from './routes';
import { usePageTrackingGA, useWebgate } from '@xvia/webgate-connect';
import {
  ServicesFiltersContextProvider,
  useServicesFilters,
} from './hooks/use-services-filters';

declare global {
  interface Window {
    VLibras: {
      Widget: new (url: string) => void;
    };
  }
}

function AppContent() {
  const location = useLocation();
  const history = useHistory();
  const { portalMessages, userInfo } = useWebgate();
  const { setFilters } = useServicesFilters();

  usePageTrackingGA(location);

  useEffect(() => {
    if (portalMessages?.detail?.pushRoute) {
      const route = portalMessages.detail.pushRoute;
      const searchMatch = route.match(/\/search\/(.+)/);

      if (searchMatch && searchMatch[1]) {
        const searchTerm = decodeURIComponent(searchMatch[1]);

        setFilters((prev) => ({
          ...prev,
          searchQuery: searchTerm,
          currentPage: 1,
        }));
      }

      history.push(route);
    }
  }, [history, portalMessages, setFilters]);

  useEffect(() => {
    const intelliframe = document.getElementById(
      'intelliframe'
    ) as HTMLIFrameElement;

    if (userInfo) {
      intelliframe?.contentWindow?.postMessage(
        { type: 'USER_INFO_RESPONSE', payload: userInfo },
        '*'
      );
    }
  }, [userInfo]);

  return (
    <Switch>
      {routes.map(({ component: RouteComponent, path, exact }) => (
        <Route key={path} path={path} exact={exact}>
          <RouteComponent />
        </Route>
      ))}
    </Switch>
  );
}

function App() {
  return (
    <ServicesFiltersContextProvider>
      <AppContent />
    </ServicesFiltersContextProvider>
  );
}

export default App;
