import React, { FC } from 'react';
import { RelevantServicesProps, Services } from './services';
import { useServicesFilters } from '../../hooks/use-services-filters';

interface ServicesContainerProps {
  title: string;
  element?: React.ReactNode;
  totalServices?: boolean;
}

export const ServicesContainer: FC<ServicesContainerProps> = ({
  title,
  element,
  totalServices = false,
}) => {
  const {
    servicesCatalogList,
    currentPage,
    setCurrentPage,
    totalPages,
    setSearch,
    isRedirectWarningModalOpen,
    toggleRedirectWarningModal,
    handleRedirectWarningModalConfirm,
    dropdownData,
    switchData,
    handleInfoClick,
    handleRequestClick,
    handleSearchClick,
    selectedCategory,
    categories,
    selectedDepartment,
    departments,
    filters,
  } = useServicesFilters();

  const relevantServicesProps: RelevantServicesProps = {
    services: servicesCatalogList?.map((service) => ({
      title: service.title,
      onInfoClick: () => handleInfoClick(service.slug),
      onRequestClick: () => handleRequestClick(service.link),
      isOnline: service.isOnline && !!service.link,
      isDigital: service.isDigital && !!service.link,
      category: service?.targets.join(', '),
      totalServices: service.resultTotal,
    })),
    switchData,
    setCurrentPage,
    currentPage,
    totalPages,
    setSearch,
    onSearchClick: handleSearchClick,
    dropdownData,
    title,
    element,
    totalServices,
    isRedirectWarningModalOpen,
    toggleRedirectWarningModal,
    handleRedirectWarningModalConfirm,
    selectedCategory,
    categories,
    selectedDepartment,
    departments,
    filters,
  };

  return <Services {...relevantServicesProps} />;
};
