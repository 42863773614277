import { Grid, styled } from '@mui/material';

export const ListWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const BaseTabsNav = styled('div')(() => ({
}));

export const BaseTabsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(780)]: {
    flexDirection: 'column',
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  width: "100%",
}));

export const WrapTabsNav = styled('div')(() => ({
  borderBottom: '1px solid var(--color-grey-40)',
}));

export const StyledGridPagination = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
}));
