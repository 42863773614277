import { styled } from '@mui/material';
import {
  AccordionSubtitle,
  AccordionTrigger,
} from '@plataformaxvia/design-system';

interface AccordionSubtitleStyledProps {
  isActive: boolean;
}

export const AccordionSubtitleStyled = styled(
  AccordionSubtitle
)<AccordionSubtitleStyledProps>(({ theme, isActive }) => ({
  fontFamily: 'var(--font-family-primary)',
  color: 'var(--color-card-accordion-subtitle)',

  '& p': {
    ...(isActive
      ? {}
      : {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '280px',
        }),
  },
}));

export const AccordionTriggerStyled = styled(AccordionTrigger)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& header': {
    width: '80%',
  },
}));

export const BaseDepartmentList = styled('div')(() => ({
  padding: '46px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const WrapDepartmentList = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(780)]: {
    flexDirection: 'column',
  },
}));

export const SideFilterBase = styled('div')(({ theme }) => ({
  width: '384px',
  transition: 'all 0.8s ease-in-out',
  [theme.breakpoints.down(780)]: {
    width: '100%',
  },
}));

export const WrapSecretariatsList = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '16px',
}));

export const BaseAccordionItem = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '8px',
}));

export const AccordionItemStyled = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  gap: '8px',
  fontSize: 'var(--font-subtitle-3)',
  lineHeight: 'var(--line-height-subtitle-3)',
  color: 'var(--color-card-accordion-item)',
  fontWeight: '500',

  '& svg': {
    color: 'var(--color-secondary)',
  },
}));

export const BaseAccordionButton = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'end',
  marginTop: '8px',
}));

export const EmptyFilter = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '0px 32px',
  width: '100%',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 'var(--font-title-h2)',
  color: 'var(--color-grey-80)',
  marginTop: '50px',
}));

export const WrapTabsNav = styled('div')(() => ({
  borderBottom: '1px solid var(--color-grey-40)',
}));

export const BaseTabsNav = styled('div')(() => ({
  maxWidth: '500px',
}));

// Tab - Governadora

export const BaseGovernor = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(995)]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const BaseGovernorPhoto = styled('img')(() => ({
  width: '100%',
  height: 'auto',
  maxHeight: '613px',
  maxWidth: '488px',
}));

export const BaseGovernorInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  flex: 1,
}));

export const GovernorInfoCard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '32px',
  backgroundColor: 'var(--color-card-governor)',
  boxShadow: '0px 4px 24px 0px #00000026',
  borderRadius: '8px',
}));

export const GovernorTitle = styled('div')(() => ({
  flex: 1,
  fontWeight: 600,
  fontSize: 'var(--font-title-h2)',
  lineHeight: 'var(--line-height-title-h2)',
  color: 'var(--color-title-contrast)',
}));

export const GovernorInfo = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  color: 'var(--color-card-governor-text)',
  fontWeight: 500,
  fontSize: 'var(--font-body-1)',
  lineHeight: 'var(--line-height-body-1)',

  '& svg': {
    color: 'var(--color-secondary)',
  },
}));

export const GovernorText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  '& strong': {
    color: 'var(--color-grey-90)',
    fontSize: 'var(--font-subtitle-2)',
    lineHeight: 'var(--line-height-subtitle-2)',
    fontWeight: 500,
  },

  '& p': {
    color: 'var(--color-governor-text)',
    fontSize: 'var(--font-body-1)',
    lineHeight: 'var(-line-height-body-1)',
    fontWeight: 300,
  },
}));
