import React, { FC } from 'react';

import { Container } from '../../components/container/container';
import {
  FeaturedNews,
  MainNews,
  MoreNews,
  NewsContent,
  NewsWrap,
  NewsWrapTitle,
  Overlay,
  TextContainer,
} from './style';
import { Button, CardMedia, Title } from '@plataformaxvia/design-system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HideImageIcon from '@mui/icons-material/HideImage';
import { useHistory } from 'react-router-dom';

interface NewsCardProps {
  info: string;
  title: string;
  imageUrl?: string;
  onClick?: () => void;
}

export interface NewsProps {
  news: NewsCardProps[];
}

export const News: FC<NewsProps> = ({ news }) => {
  let history = useHistory();
  return (
    <>
      {news && news.length > 0 && (
        <NewsWrap>
          <Container>
            <NewsWrapTitle>
              <div>
                <Title title="Notícias" />
              </div>
              <Button
                label="Mais notícias"
                size="large"
                iconEnd={<ArrowForwardIcon />}
                variant="primary"
                style={{ width: 'auto' }}
                onClick={() => history.push('noticias')}
              />
            </NewsWrapTitle>
            <NewsContent>
              <MainNews>
                <FeaturedNews imageUrl={news[0].imageUrl}>
                  {!news[0].imageUrl && (
                    <HideImageIcon fontSize="large" className="hideIcon" />
                  )}
                  <Overlay className="overlay" onClick={news[0].onClick}>
                    <TextContainer>
                      <p>{news[0].info}</p>
                      {news[0].title}
                    </TextContainer>
                  </Overlay>
                </FeaturedNews>
              </MainNews>

              <MoreNews>
                {news.slice(1, 4).map((newsItem, index) => (
                  <CardMedia
                    key={index}
                    info={newsItem.info}
                    title={newsItem.title}
                    imageUrl={newsItem.imageUrl}
                    onClick={newsItem.onClick}
                  />
                ))}
              </MoreNews>
            </NewsContent>
          </Container>
        </NewsWrap>
      )}
    </>
  );
};
