import { Box, Grid, styled, Typography } from '@mui/material';

interface BaseVideoProps {
  isVideoSmallScreen: boolean;
}

export const ListWrap = styled('div')(() => ({
  padding: '56px 0px',
  backgroundColor: 'var(--color-background-primary)',
}));

export const BannerWrap = styled('div')(() => ({
  backgroundColor: 'var(--color-background-secondary)',
}));

export const BaseTabsNav = styled('div')(() => ({}));

export const BaseTabsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  [theme.breakpoints.down(780)]: {
    flexDirection: 'column',
  },
}));

export const StyledGrid = styled(Grid)(() => ({
  width: '100%',
}));

export const WrapTabsNav = styled('div')(() => ({
  borderBottom: '1px solid var(--color-grey-40)',
}));

export const BaseButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '32px',
}));

export const BaseVideo = styled(Box)<BaseVideoProps>(
  ({ theme, isVideoSmallScreen }) => ({
    width: '100%',
    borderRadius: '8px',
    backgroundColor: 'var(--color-background-base-video)',
    boxShadow: '0px 4px 24px 0px #00000026',
    marginTop: '40px',
    padding: '32px',
    gap: '32px',
    display: 'grid',
    gridTemplateColumns: isVideoSmallScreen ? '1fr' : '2fr 1fr',
    [theme.breakpoints.down(995)]: {
      flexDirection: 'column',
    },
  })
);

export const MainNews = styled('div')(() => ({
  width: '100%',
  flex: 1,
  height: '444px',
  borderRadius: '8px',
}));

export const BaseTitleVideo = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  marginTop: '8px',
}));

export const TitleVideo = styled('div')(() => ({
  fontSize: 'var(--font-subtitle-2)',
  color: 'var(--color-title-contrast)',
}));

export const MoreNews = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  flex: 1,
}));

export const Marker = styled('div')(() => ({
  minWidth: '5px',
  alignSelf: 'stretch',
  borderRadius: '5px',
  backgroundColor: 'var(--color-grey-40)',
}));

export const BaseVideoList = styled(Box)(() => ({
  maxHeight: '400px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
}));

export const CardVideo = styled(Box)(() => ({
  cursor: 'pointer',
  marginBottom: '8px',
  borderRadius: 1,
  '&:hover': {
    backgroundColor: 'var(--color-background-primary)',
  },
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));

export const TitleCardVideo = styled(Typography)(() => ({
  fontSize: 'var(--font-subtitle-3)',
  color: 'var(--color-title-contrast)',
  margin: '0px',
  width: '300px',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
