import React, { FC } from "react";
import { BannerSlider, BannerSliderProps } from "./banner-slider";

export const BannerSliderContainer: FC = () => {

  const banners = [
    {
      img: `${process.env.PUBLIC_URL}/images/banner/banner-1.png`,
      alt: "Programa Ronda do Bairro",
    },
    {
      img: `${process.env.PUBLIC_URL}/images/banner/banner-2.png`,
      alt: "Programa Vida Nova nas Grotas de São Paulo",
    },
    {
      img: `${process.env.PUBLIC_URL}/images/banner/banner-3.png`,
      alt: "Programa Cria Criança",
    },
    {
      img: `${process.env.PUBLIC_URL}/images/banner/banner-4.png`,
      alt: "Programa Cria Criança",
    },
  ];

  const props: BannerSliderProps = {
    banners
  };

  return <BannerSlider {...props} />;
};
